import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import author1 from "../../images/users/user1.jpg";
import author2 from "../../images/users/user2.jpg";
import author3 from "../../images/users/user3.jpg";
import Breadcumb from "../../images/success-stories/breadcumb.png";
import AboutUs from "../../images/success-stories/about.jpg";
import ChildEducation from "../../images/success-stories/child-education.jpg";
import DrugReleif from "../../images/success-stories/drug-releif.jpeg";
import MentalHealth from "../../images/success-stories/mental-health.jpeg";
import EyeDonation from "../../images/success-stories/eye-donation.jpeg";
import JainValues from "../../images/success-stories/jain-values.JPG";
import Story from "../../images/success-stories/story.png";
import { Helmet } from "react-helmet";

const blogs = [
  {
    id: "1",
    title: "Empowering Children Through Education",
    slug: "Start-a-fundraiser-for-yourself-in-World-Charity-Day",
    screens: ChildEducation,
    authorImg: author1,
    description:
      "A rural community school was struggling with limited resources and outdated teaching materials. Through Jainam Jivika Foundation’s intervention, volunteers provided books, digital learning tools and mentorship sessions. Over 200 students now enjoy an enriched learning environment, igniting dreams of a brighter future.",
    authorTitle: "Sineor Consultant",
    create_at: "12.January.2024",
    comment: "356",
    thumb: "Charity",
    blClass: "format-standard-image",
    col: "col-md-12 col-12",
    styleClass: "blogBlock--style1",
  },
  {
    id: "2",
    title: "Providing Relief During Disasters",
    slug: "do-you-know-why-african-people-year-of-year-waterless",
    screens: DrugReleif,
    authorImg: author2,
    description:
      "During severe flooding in a village, Jainam Jivika Foundation mobilised a team to distribute essential relief materials, including food, water and medical kits. More than 500 families received timely help, restoring their hope and dignity in a time of crisis.",
    author: "Savannah Nguyen",
    authorTitle: "Creative Director",
    create_at: "22.January.2024",
    comment: "450",
    thumb: "Health",
    blClass: "format-standard-image",
    col: "col-md-6 col-12",
    styleClass: "blogBlock--style2",
  },
  {
    id: "3",
    title: "Promoting Mental Health Awareness",
    slug: "Why-you-should-join-as-a-volunteer-with-us-2024",
    screens: MentalHealth,
    authorImg: author3,
    description: (
      <>
        Through our Mind Unload initiative, we organised mindfulness workshops
        for college students facing academic pressure. The sessions empowered
        participants to manage stress effectively and inspired discussions
        around mental health in their institutions.
      </>
    ),
    author: "Brooklyn Simmons",
    authorTitle: "Art Director",
    create_at: "25.January.2024",
    comment: "905",
    thumb: "Education",
    blClass: "format-video",
    col: "col-md-6 col-12",
    styleClass: "blogBlock--style2",
  },
  {
    id: "4",
    title: "Restoring Vision Through Eye Donations",
    slug: "Start-a-fundraiser-for-yourself-in-World-Charity-Days",
    screens: EyeDonation,
    authorImg: author1,
    description: (
      <>
        A 40-year-old homemaker received her eyesight back through our Akshay
        Netra initiative. With a successful corneal transplant, she not only
        regained her vision but also the ability to support her family’s
        livelihood.
      </>
    ),
    author: "Kathryn Murphy",
    authorTitle: "Sineor Consultant",
    create_at: "12.January.2024",
    comment: "356",
    thumb: "Charity",
    blClass: "format-standard-image",
    col: "col-md-12 col-12",
    styleClass: "blogBlock--style1",
  },
  {
    id: "5",
    title: "Youth Empowerment with Jain Values",
    slug: "Start-a-fundraiser-for-yourself-in-World-Charity-Days",
    screens: JainValues,
    authorImg: author1,
    description: (
      <>
        Through our Young Jains programme, we organised interactive workshops on
        non-violence and mindfulness for 150 young participants. These sessions
        helped them connect with their cultural heritage while fostering a
        deeper sense of compassion and responsibility.
      </>
    ),
    author: "Kathryn Murphy",
    authorTitle: "Sineor Consultant",
    create_at: "12.January.2024",
    comment: "356",
    thumb: "Charity",
    blClass: "format-standard-image",
    col: "col-md-12 col-12",
    styleClass: "blogBlock--style1",
  },
];

const SuccessStories = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Helmet>
        <title>JJFIndia | Success Stories</title>
        <meta
          name="description"
          content="Welcome to Jainam Jivika Foundation's home page."
        />
      </Helmet>
      <Header hclass={"header--styleFour"} />
      <main className="page_content">
        <PageTitle
          pageTitle={"Success Stories"}
          pagesub={"Impacts & Stories"}
          bg={Breadcumb}
        />

        {/* <div className='mt-5 pt-4'>
                    <About2 />
                </div> */}

        <section className="events pt-60 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="eventsBlock" style={{border:'none'}}>
                  <figure className="eventsBlock__thumb">
                    <Link
                      onClick={ClickHandler}
                      to="/"
                      className="eventsBlock__thumb__link"
                    >
                      <img
                        src={AboutUs}
                        width={550}
                        alt="Join the Movement"
                        className="eventsBlock__thumb__image"
                      />
                    </Link>
                  </figure>
                  <div className="eventsBlock__content">
                    <span className="sectionTitle__small justify-content-start">
                      <i className="fa-solid fa-heart btn__icon"></i>
                      Empowering Communities, Transforming Lives
                    </span>
                    <h3 className="eventsBlock__heading text-uppercase">

                      Transforming Lives Across Communities

                    </h3>

                    <p
                      className="eventsBlock__text mb-2"
                      style={{ textAlign: "justify" }}
                    >
                      At Jainam Jivika Foundation, every initiative is a step
                      towards creating meaningful change. From supporting
                      education and health to preserving cultural values and
                      promoting social reforms, our projects are touching lives
                      and inspiring hope. These success stories showcase the
                      impact of collective efforts in building stronger, more
                      compassionate communities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="pt-40 gray-bg">
          <section className="blog">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="sectionTitle mb-30">
                    <span className="sectionTitle__small justify-content-center">
                      <i className="fa-solid fa-heart btn__icon"></i>
                      Inspiring Stories of Change
                    </span>
                    <h2 className="sectionTitle__big text-center">
                      Real Stories of Hope, Change and Impact
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                {blogs.slice(0, 5).map((blog, Bitem) => (
                  <div className="col-md-6" key={Bitem}>
                    <div className={`blogBlock blogBlock--style3 mb-40`}>
                      <figure className="blogBlock__figure">
                        <a
                          className="blogBlock__figure__link linkk"
                        >
                          <img
                            src={blog.screens}
                            style={{ height: "400px", width: "100%" }}
                            alt="Stronger Together"
                            className="blogBlock__figure__image"
                          />
                        </a>
                      </figure>
                      <div className="blogBlock__content success-story-responsive">
                        <div className="blogBlock__header">
                          {/* <span className="blogBlock__tag tag mb-20">{blog.thumb}</span> */}
                          <h2 className="blogBlock__heading heading text-uppercase mb-20">
                            <a
                              className="blogBlock__heading__link"
                            >
                              {blog.title}
                            </a>
                          </h2>
                        </div>
                        <div className="blogBlock__body">
                          <p
                            className="blogBlock__text text mb-0"
                            style={{ textAlign: "justify" }}
                          >
                            {blog.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>

        <div className="volunteer pt-40 pb-40 ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-10">
                <div className="volunteerUser">
                  <div className="volunteerUser__box">
                    <div className="volunteerUser__thumb">
                      <img src={Story} alt="Join Us and Make a Difference" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="aboutContent aboutContent--style3">
                  <div className="sectionTitle mb-20">
                    <span className="sectionTitle__small">
                      <i className="fa-solid fa-heart btn__icon"></i>
                      Share Your Story
                    </span>
                    <h2 className="sectionTitle__big">Your Story Matters</h2>
                  </div>
                  <p
                    className="aboutContent__text"
                    style={{ textAlign: "justify" }}
                  >
                    We would be happy to know your story of impact, too! Share
                    your experiences and inspire others by being part of this
                    journey towards a brighter, kinder future.
                  </p>
                  <div className="aboutContent__buttons">
                    <Link
                      onClick={ClickHandler}
                      className="btn btn--styleOne btn--primary it-btn"
                      to="/testimonials"
                    >
                      <span className="btn__text">Join Our Initiatives</span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default SuccessStories;
