import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero2 from '../../components/hero2/hero2';
import StoriesSection2 from '../../components/StoriesSection2/StoriesSection2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import About from '../../components/about/about';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import About3 from '../../components/about3/about3';
import MissionVision from '../../components/MissionVision/MissionVision';
import Testimonial from '../../components/Testimonial/Testimonial';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import m from '../../images/auth/male.png'
import HomeBanner2 from '../../images/home/home-banner-2.jpg'
import BigImpact from '../../images/home/big-impact.png'
import CommunityFocused from '../../images/home/community-focused.png'
import FutureReady from '../../images/home/future-ready.png'
import JoinSectionImg from '../../images/home/join-home.jpg'
import TakeaPledge from '../../images/home/take-a-pledge.png'
import BecomeVolunteer from '../../images/home/become-volunteer.png'
import SupportNGO from '../../images/home/support-ngo.png'
import Donation from '../../images/home/donation.png'
import TestimonialBannerImg from '../../images/home/home-testimonial.jpg'
import TestimonialImg1 from '../../images/home/testimonial-home-1.jpg'
import TestimonialImg2 from '../../images/home/testimonial-home-2.jpg'
import TestimonialImg3 from '../../images/home/testimonial-home-3.jpg'
import TestimonialImg4 from '../../images/home/testimonial-home-4.jpg'
import HomeVolunteerHero from '../../images/home/home-volunteer-hero.jpg'
import HomeVolunteerHero1 from '../../images/home/home-volunteer-1.jpg'
import HomeVolunteerHero2 from '../../images/home/home-volunteer-2.jpg'
import HomeVolunteerHero3 from '../../images/home/home-volunteer-3.jpg'
import HomeVolunteerHero4 from '../../images/home/home-volunteer-4.jpg'
import HomeVolunteerHero5 from '../../images/home/home-volunteer-5.jpg'
import HomeCtaHero from '../../images/home/home-cta-hero.jpg'
import HomeCta1 from '../../images/home/home-cta-1.jpg'
import HomeCta2 from '../../images/home/home-cta-2.jpg'
import HomeCta3 from '../../images/home/home-cta-3.jpg'
import HomeCta4 from '../../images/home/home-cta-4.jpg'
import HomeCta5 from '../../images/home/home-cta-5.jpg'
import { Link } from 'react-router-dom';


const Features = [
    {
        title: 'Big Impact',
        des: <> We address diverse challenges with thoughtful and practical solutions, creating meaningful and lasting social impact.</>,
        icon: BigImpact,
        width: '100%',
        duration: 1000,
    },
    {
        title: 'Community Focused',
        des: <>We create community support by building strong networks of volunteers and supporters dedicated to helping communities.</>,
        icon: CommunityFocused,
        width: '100%',
        duration: 1200,
    },
    {
        title: 'Future Ready',
        des: <> We combine traditional values with modern ideas to ensure lasting social good for generations to come. </>,
        icon: FutureReady,
        width: '100%',
        duration: 1400,
    },
]

const Features2 = [
    {
        title: 'Become a Volunteer',
        des: 'Use your time and skills to support our initiatives and bring positive change to communities.',
        icon: BecomeVolunteer,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Take a Pledge',
        des: 'Commit to making a meaningful difference by supporting impactful causes that empower and uplift those in need.',
        icon: TakeaPledge,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Support NGOs',
        des: 'Help trusted NGOs achieve their goals by offering your support and spreading their message.',
        icon: SupportNGO,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Donate',
        des: 'Your donation can provide resources like education, healthcare and shelter, creating a better future for many.',
        icon: Donation,
        width: '70%',
        duration: 1400,
    },
]

const testimonial = [
    {
        id: '01',
        tImg: m,
        Title: 'Donor',
        Des: 'Donating through Jainam Jivika Foundation was a life-changing experience. Seeing how my donation directly helped someone in need made me realise the power of kindness and community.',
        Name: 'Donor',
        sub: 'Director of Marketing',
    },
    {
        id: '02',
        tImg: m,
        Title: 'Volunteer',
        Des: 'Jainam Jivika Foundation gave me the opportunity to volunteer and contribute to causes I deeply care about. It’s inspiring to see how my small efforts can create such a big impact on people’s lives.',
        Name: 'Volunteer',
        sub: 'Tech Startup Founder',
    },
    {
        id: '03',
        tImg: m,
        Title: 'Fitness Enthusiast',
        Des: 'Through Jainam Jivika Foundation’s My Food Checker initiative, I learned how to make healthier food choices. Their programmes are practical and easy to follow and they’ve truly improved my lifestyle.',
        Name: 'Fitness Enthusiast',
        sub: 'Monprofit Director',
    },
    {
        id: '04',
        tImg: m,
        Title: 'Young Leader',
        Des: 'Jainam Jivika Foundation’s workshops and events helped me reconnect with my values and culture. Their efforts to inspire and empower individuals are truly commendable.',
        Name: 'Young Leader',
        sub: 'Monprofit Director',
    },
    {
        id: '04',
        tImg: m,
        Title: 'Social Reformer',
        Des: 'Jainam Jivika Foundation’s team goes above and beyond to support both donors and recipients. Their programmes are well-organised and impactful, making it easy for anyone to contribute meaningfully to society.',
        Name: 'Social Reformer',
        sub: 'Monprofit Director',
    },
]


const MainPage = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <div>
                <Header hclass={'header--styleTwo'} />
                <main className="page_content">
                    <Hero2 />
                    <About subtitle="About Jainam Jivika Foundation" className="resp-home-about" title="Helping Communities, Changing Lives" img={HomeBanner2} para1={<>
                        Jainam Jivika Foundation is a social organisation dedicated to improving lives and bringing positive change to communities. Our goal is to support individuals and strengthen communities through meaningful initiatives that address important social issues. We are committed to creating a positive social impact by addressing key areas like health awareness and mental health support. Our efforts also include promoting cultural preservation and environmental protection to ensure a better, more sustainable future for all.
                    </>} para2="" cta="Be a Part of the Change" button="Donate Now" navigate="" />

                    <section className='about'>
                        <FeaturesSection Features={Features} height="responsive-home-features" count="2" range="MILLION" work="HOURS OF IMPACTFUL PROJECTS BY 2025" navigate='' button="Join the Mission" campaigns={false} />
                    </section>

                    <div className='pb-50'>
                        <MissionVision />
                    </div>

                    {/* <FeaturesSection2 /> */}


                    <div className='gray-bg'>
                        <ServiceSection subtitle="Our Initiatives" appLogo={true} title="Creating Impact Through Our Transformative Initiatives" className="responsive-service-home" startIndex="0" endIndex="8" />
                    </div>

                    {/* <JoinSection subtitle="Be a Part of the Change!" title="Join Hands to Uplift Communities Together" para="Become a volunteer and use your time and skills to empower communities. Can’t volunteer? Support our initiatives or partner with NGOs to help drive impactful change and create brighter futures for those in need." cta="Become a Volunteer" button="Support Our Initiatives" img={JoinSectionImg} /> */}

                    <>

                        <div className="container">
                            <div className="row align-items-center justify-content-between pt-40 pb-20">
                                <div className="col-lg-5 mb-15">
                                    <div className="">
                                        <img src={JoinSectionImg} alt="Join the Movement" />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-30">
                                    <div className="aboutContent aboutContent--style2">
                                        <div className="sectionTitle mb-20">
                                            <span className="sectionTitle__small">
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                Be a Part of the Change!
                                            </span>
                                            <h2 className="sectionTitle__big">
                                                Join Hands to Uplift Communities Together
                                            </h2>
                                        </div>
                                        <p
                                            className="aboutContent__text"
                                            style={{ textAlign: "justify" }}
                                        >
                                            Become a volunteer and use your time and skills to empower communities. Can’t volunteer? Support our initiatives or partner with NGOs to help drive impactful change and create brighter futures for those in need.
                                        </p>
                                        <span className="aboutContent__quote mb-3">
                                            Become a Volunteer
                                        </span>
                                        <div className="aboutContent__buttons">
                                            <Link
                                                to="/contact-us"
                                                onClick={ClickHandler}
                                                className="btn btn--styleOne btn--secondary it-btn"
                                            >
                                                <span className="btn__text">Support Our Initiatives</span>
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                <span className="it-btn__inner">
                                                    <span className="it-btn__blobs">
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                    </span>
                                                </span>
                                                <svg
                                                    className="it-btn__animation"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                >
                                                    <defs>
                                                        <filter>
                                                            <feGaussianBlur
                                                                in="SourceGraphic"
                                                                result="blur"
                                                                stdDeviation="10"
                                                            ></feGaussianBlur>
                                                            <feColorMatrix
                                                                in="blur"
                                                                mode="matrix"
                                                                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                                                result="goo"
                                                            ></feColorMatrix>
                                                            <feBlend
                                                                in2="goo"
                                                                in="SourceGraphic"
                                                                result="mix"
                                                            ></feBlend>
                                                        </filter>
                                                    </defs>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                    <div className='gray-bg'>
                        <About3 ctaSection={false} subtitle="NEED YOUR SUPPORT" title="Ways You Can Contribute" boxResp={true} className="responsive-support-divs" Features2={Features2} />
                    </div>

                    {/* <Testimonial3 /> */}

                    <div className='pb-0'>
                        <Testimonial testimonial={testimonial} subtitle="TESTIMONIALS" title="What People Say About Jainam Jivika Foundation?" img={TestimonialBannerImg} img1={TestimonialImg1} img2={TestimonialImg2} img3={TestimonialImg3} img4={TestimonialImg4} />
                    </div>

                    {/* <div className='' style={{ height: '100%' }}>
                        <FunFactSection subtitle="Uniting Efforts for a Brighter Tomorrow" title="We Have Made a Difference Together" FunFact={FunFact} img={FunFactImgHome} />
                    </div> */}

                    {/* <About4 /> */}

                    {/* <Hero/> */}
                    {/* <PartnerSection /> */}
                    <StoriesSection2 />

                    <div className='pt-50 gray-bg'>
                        <CtaSection subtitle="VOLUNTEER" title="Be the Change You Want to See!" para="Join Jainam Jivika Foundation as a volunteer and use your time, skills and passion to uplift communities and support impactful causes. Whether it’s teaching children organising events, donating blood or helping with environmental initiatives, your contribution can transform lives and create a brighter future. More than 200 people have already joined us; why not you? Together, we can make a lasting impact!" button="Become a Volunteer" img={HomeVolunteerHero} img1={HomeVolunteerHero1} img2={HomeVolunteerHero2} img3={HomeVolunteerHero3} img4={HomeVolunteerHero4} img5={HomeVolunteerHero5} />
                    </div>

                    {/* <div className='pt-40 pb-40  gray-bg'>
                        <BlogSection2 subtitle="NEWS FEED" title="LATEST NEWS & ARTICLES" className1="responsive-blogs" className2='absoute-blog-buttons' className3="blog-descriptionn" />
                    </div> */}


                    {/* <div className='mt-5 pt-5'>
                        <About2 />
                    </div> */}

                    <div className='pt-40 pb-20 '>
                        <CtaSection subtitle="Together, we can empower lives, bring hope and create a brighter tomorrow. " title="Your Journey Towards Making an Impact Starts Here!" button="Join Us" img={HomeCtaHero} img1={HomeCta1} img2={HomeCta2} img3={HomeCta3} img4={HomeCta4} img5={HomeCta5} />
                    </div>

                </main>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default MainPage;