import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import CtaSection from '../../components/CtaSection/CtaSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import BreadcumbImg from '../../images/food-checker/food-checker-breadcumb.jpeg'
import AboutUs from '../../images/food-checker/food-checker-about-1.png'
import Icon1 from '../../images/food-checker/safe.png'
import Icon2 from '../../images/food-checker/habits.png'
import Icon3 from '../../images/food-checker/save-time.png'
import Icon4 from '../../images/food-checker/peace-of-mind.png'
import Scanning from '../../images/food-checker/barcode-scan.png'
import Meal from '../../images/food-checker/meal.png'
import Safety from '../../images/food-checker/public-safety.png'
import Nutritional from '../../images/food-checker/nutrition.png'
import Progress from '../../images/food-checker/progress.png'
import HomeCtaHero from '../../images/food-checker/cta-img.png'



const Features2 = [
    {
        title: 'Scan Food Barcodes',
        des: 'Instantly check ingredients, allergens and nutritional details.',
        icon: Scanning,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Customised Meal Plans',
        des: 'Tailored to meet your health goals, whether it’s weight management, fitness or allergy safety.',
        icon: Meal,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Nutritional Insights',
        des: 'Dive into detailed analyses of calories, macronutrients and vitamins.',
        icon: Nutritional,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Track Progress',
        des: 'Monitor dietary habits and see improvements over time with easy-to-read summaries.',
        icon: Progress,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Family Safety',
        des: 'Ensure every member’s dietary needs are met, all from one app.',
        icon: Safety,
        width: '70%',
        duration: 1400,
    },
]

const MyFoodChecker = (props) => {


    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'My Food Checker'} pagesub={'Our Initiatives'} bg={BreadcumbImg} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="My Food Checker" img={AboutUs} title="Your Trusted Food Companion" para={<>My Food Checker is our initiative where we make food choices simpler, safer and healthier. We help you understand food ingredients, detect allergens and make informed decisions tailored to your food preferences. Whether you’re managing allergies, following a specific diet or just aiming for healthier eating, My Food Checker is your reliable companion in every step of your journey.</>} />
                </div>


                <div className='gray-bg pb-40' id='feature'>
                    <About4 subtitle="About the Initiative" title="What is My Food Checker?" para="At My Food Checker, we believe that knowledge is power, especially when it comes to food. Our platform allows you to scan food barcodes to quickly understand their ingredients, detect allergens and see if they fit your dietary preferences.
Whether you’re avoiding allergens, following a specific diet or looking for healthier alternatives, we are here to help. It’s not just about scanning labels; it’s about making food choices that improve the quality of your life." button={false} icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} title1="Stay Safe" des1="Avoid allergens and harmful ingredients effortlessly." title2="Healthier Choices" des2="Understand your food and make smarter decisions." title3="Save Time" des3="Filter products that match your dietary needs in seconds." title4="Peace of Mind" des4="Eat confidently, knowing exactly what’s in your food." />
                </div>

                <div className='white-bg'>
                    <ServiceSection subtitle="Why Choose us" title="Why Choose My Food Checker?" startIndex="21" endIndex="26" naviagtion={true} />
                </div>

                <div className='gray-bg'>
                    <About3 bg="white" ctaSection={false} subtitle="Your Guide to Safer, Smarter Eating" title="What We Offer?" boxes={3} className="my-food-checker-div-respo" Features2={Features2} ctabutton={false} button="KNOW MORE" />
                </div>

                <div className='pt-40 white-bg'>
                    <CtaSection subtitle="Empowering You to Eat Better, Every Day." width={500} title="Take Control of Your Food Choices Today!" para="Take the first step toward safer, healthier eating for you and your family. With My Food Checker, every bite can be a confident choice!" button="Start Your Journey" ctaLink="https://myfoodchecker.mini91.com/" img={HomeCtaHero}/>
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default MyFoodChecker;
