import React from 'react'
import { Link } from 'react-router-dom'
import mask from '../../images/shapes/mask-shape2.svg'
import shape4 from '../../images/shapes/mask-shape3.svg'

const About = (props) => {


    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <>
            {/* <img className="about__shape about__shape--one" src={shape1} alt="Act for Change" />
            <img className="about__shape about__shape--two" src={shape2} alt="Act for Change" />
            <img className="about__shape about__shape--three" src={shape3} alt="Act for Change" /> */}
            <div className="aboutArea aboutArea--padding position-relative mt-3" id='feature'>
                <img className="mask-aboutBack wow animate__fadeInLeft animate__animated" data-wow-duration="1200ms" data-wow-delay="300ms" src={shape4} alt="Act for Change" />
                <div className="mask mask--about wow animate__fadeInLeft animate__animated" data-wow-duration="1200ms" data-wow-delay="200ms">
                    <img src={props.img} alt="Act for Change" className="mask__thumb" />
                    <img className="mask__overlay" src={mask} alt="Act for Change" />
                </div>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-xl-6 col-xxl-7 mb-10">
                            <div className="aboutContent">
                                <div className="sectionTitle mb-20">
                                    <span className="sectionTitle__small">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        {props.subtitle}
                                    </span>
                                    <h2 className="sectionTitle__big">{props.title}</h2>
                                </div>
                                <p className="aboutContent__text mb-2" style={{textAlign:'justify'}}>
                                    {props.para1}
                                </p>
                                <p className="aboutContent__text mt" style={{textAlign:'justify'}}>
                                    {props.para2}
                                </p>
                                <span className="aboutContent__quote mb-3">{props.cta}</span>
                                <Link to="/donate" onClick={ClickHandler} className="btn btn--styleOne btn--secondary it-btn">
                                    <span className="btn__text">{props.button}</span>
                                    <i className="fa-solid fa-heart btn__icon"></i>
                                    <span className="it-btn__inner">
                                        <span className="it-btn__blobs">
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                        </span>
                                    </span>
                                    <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter>
                                                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                </feGaussianBlur>
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                </feColorMatrix>
                                                <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default About;