import React from 'react'
import { Link } from 'react-router-dom'

const About4 = (props) => {


    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about">
            {/* <img className="about__shape about__shape--one" src={shape1} alt="Act for Change" /> */}
            {/* <img className="about__shape about__shape--two" src={shape2} alt="Act for Change" />
            <img className="about__shape about__shape--three" src={shape3} alt="Act for Change" /> */}
            <div className="aboutArea aboutArea--padding position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="aboutContent">
                                <div className="sectionTitle mb-20">
                                    <span className="sectionTitle__small">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        {props.subtitle}
                                    </span>
                                    <h2 className="sectionTitle__big">{props.title}</h2>
                                </div>
                                <p className="aboutContent__text" style={{ textAlign: 'justify' }}>
                                    {props.para}
                                </p>
                                {props.button && <Link to='/donate' onClick={ClickHandler} className="btn btn--styleOne btn--secondary it-btn">
                                    <span className="btn__text">donate now</span>
                                    <i className="fa-solid fa-heart btn__icon"></i>
                                    <span className="it-btn__inner">
                                        <span className="it-btn__blobs">
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                        </span>
                                    </span>
                                    <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter>
                                                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                </feGaussianBlur>
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                </feColorMatrix>
                                                <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                </Link>}
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="row g-4 align-items-stretch">
                                <div className="col-lg-6 col-md-6 col-sm-6 ">
                                    <div className="missionBlock bgSecondary align-items-start flex-column gap-3 h-100">
                                        <div className="missionBlock__icon pt-2" >
                                            <img src={props.icon1} width={60} alt="Hope in Action" />
                                        </div>
                                        <div className="missionBlock__content">
                                            <span className="text-white" style={{ fontSize: '20px', fontWeight: '700' }}>{props.title1}</span>
                                            <p className="missionBlock__title" style={{ letterSpacing: '0.06em', paddingTop: '7px', textAlign:'justify' }}>{props.des1}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="missionBlock bgPrimary h-100 align-items-start flex-column gap-3">
                                        <div className="missionBlock__icon pt-2" >
                                            <img src={props.icon2} width={60} alt="Hope in Action" />
                                        </div>
                                        <div className="missionBlock__content">
                                            <span className="text-white" style={{ fontSize: '20px', fontWeight: '700' }}>{props.title2}</span>
                                            <p className="missionBlock__title" style={{ letterSpacing: '0.06em', paddingTop: '7px', textAlign:'justify' }}>{props.des2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="missionBlock bgPrimary h-100 align-items-start flex-column gap-3">
                                        <div className="missionBlock__icon pt-2" >
                                            <img src={props.icon3} width={60} alt="Hope in Action" />
                                        </div>
                                        <div className="missionBlock__content">
                                            <span className="text-white" style={{ fontSize: '20px', fontWeight: '700' }}>{props.title3}</span>
                                            <p className="missionBlock__title" style={{ letterSpacing: '0.06em', paddingTop: '7px', textAlign:'justify' }}>{props.des3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="missionBlock bgSecondary h-100 align-items-start flex-column gap-3">
                                        <div className="missionBlock__icon pt-2" >
                                            <img src={props.icon4} width={60} alt="Hope in Action" />
                                        </div>
                                        <div className="missionBlock__content">
                                            <span className="text-white" style={{ fontSize: '20px', fontWeight: '700' }}>{props.title4}</span>
                                            <p className="missionBlock__title" style={{ letterSpacing: '0.06em', paddingTop: '7px', textAlign:'justify' }}>{props.des4}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FeaturesSection3 /> */}

        </section>
    )
}

export default About4;