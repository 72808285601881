import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Breadcumb from '../../images/reform-army/breadcumb.jpg'
import AboutUs from '../../images/reform-army/aboutus.png'
import Icon1 from '../../images/reform-army/add-1.png'
import Icon2 from '../../images/reform-army/treaty-1.png'
import Icon3 from '../../images/reform-army/mark-1.png'
import Icon4 from '../../images/reform-army/target-1.png'
import StartCampaign from '../../images/reform-army/advertisement.png'
import Petitions from '../../images/reform-army/treaty.png'
import World from '../../images/reform-army/earth-grid.png'
import MainCta from '../../images/reform-army/empower-citizen.png'

const Features2 = [
    {
        title: 'Start a Campaign',
        des: 'Create and launch your own petition to raise awareness and gather support.',
        icon: StartCampaign,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Support Existing Petitions',
        des: 'Browse active campaigns and send pre-filled emails to decision-makers.',
        icon: Petitions,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Spread the Word',
        des: 'Share campaigns on social media and messaging platforms to amplify their reach.',
        icon: World,
        width: '70%',
        duration: 1400,
    },
]

const ReformArmy = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Reform Army'} pagesub={'Our Initiatives'} bg={Breadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Reform Army" title="Empowering Voices, Driving Change" img={AboutUs} para={<>Reform Army is the platform for taking action on the issues that matter. Whether it's protecting the environment or fighting for social justice, we help connect people with decision-makers to create real change. You can start your own campaign or support causes that need your voice. Reform Army makes advocacy simple, accessible and effective.</>} />
                </div>

                <div className='gray-bg pb-40' id='feature'>
                    <About4 subtitle="About the Initiative" title="What is Reform Army?" para="Reform Army is a platform that empowers individuals and communities to take action on issues that matter to them. It allows users to connect directly with decision-makers through impactful campaigns and petitions. By raising concerns and tracking results, Reform Army turns voices into meaningful a change for society." icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} button={false} title1="Start Campaigns" des1="Launch impactful email campaigns that reach decision-makers." title2="Support Petitions" des2="Join campaigns that align with your beliefs and lend your support." title3="Raise Concerns" des3="Highlight critical issues affecting communities to decision-makers." title4="Track Impact" des4="Get real-time instant updates on how your efforts are creating change." />
                </div>

                <div className='white-bg'>
                    <ServiceSection subtitle="Why Choose Reform Army?" title="A Platform Built for Change" center={true} startIndex="40" endIndex="44" naviagtion={true} />
                </div>

                <div className='gray-bg'>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="Become Part of the Solution" title="How Can You Get Involved?" Features2={Features2} ctabutton={false} className="reform-army-div-responsive" button="KNOW MORE" />
                </div>

                <div className="volunteer pt-40 pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={MainCta} width={550} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Make an Impact with Reform Army
                                        </span>
                                        <h2 className="sectionTitle__big">Start Your Campaign Today</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{ textAlign: 'justify' }}>
                                        Join a platform dedicated to creating a better world. Take a stand for the causes you care about and ensure your voice is heard where it matters most.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <a onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" target='_blank' rel='noopener noreferrer' href="https://reformarmy.com/">
                                            <span className="btn__text">Explore Trending Campaigns</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='pt-20'><strong>Together, let’s turn concerns into actionable change.</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ReformArmy;
