import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About4 from '../../components/about4/about4';
import CtaSection from '../../components/CtaSection/CtaSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Breadcumb from '../../images/mind-unload/mind-unload-breadcumb.jpeg'
import AboutUs from '../../images/mind-unload/about-us.png'
import Icon1 from '../../images/mind-unload/anonymous-chat.png'
import Icon2 from '../../images/mind-unload/meeting.png'
import Icon3 from '../../images/mind-unload/resources.png'
import Icon4 from '../../images/mind-unload/service.png'
import PrivateSupport from '../../images/mind-unload/discussion.png'
import HelpTools from '../../images/mind-unload/stress-relief.png'
import SupportHelp from '../../images/mind-unload/helpline.png'
import Resources from '../../images/mind-unload/utilization.png'
import HomeCtaHero from '../../images/home/home-volunteer-hero.jpg'
import HomeVolunteerHero1 from '../../images/home/home-volunteer-1.jpg'
import HomeVolunteerHero2 from '../../images/home/home-volunteer-2.jpg'
import HomeVolunteerHero3 from '../../images/home/home-volunteer-3.jpg'
import HomeVolunteerHero4 from '../../images/home/home-volunteer-4.jpg'
import HomeVolunteerHero5 from '../../images/home/home-volunteer-5.jpg'
import sImg3 from '../../images/utilites/dontaion-details-thumb1.jpg'
import Stress from '../../images/mind-unload/consulting.png'
import Space from '../../images/mind-unload/secure.png'
import CommunityCare from '../../images/mind-unload/solidarity.png'
import Workshop from '../../images/mind-unload/presentation.png'

const mindUnloadFeatures = [
    {
        Id: '27',
        sImg: sImg3,
        icon: Stress,
        title: 'Effortless Stress Relief',
        slug: 'Build-Home',
        description: 'Experience guided meditations, mindfulness exercises and creative outlets like art and journaling to restore balance and calm.',
    },
    {
        Id: '28',
        sImg: sImg3,
        icon: Space,
        title: 'Safe Space for Expression',
        slug: 'Build-Home',
        description: 'Share your feelings openly in a judgment-free environment. Trained listeners and supportive sessions offer clarity and emotional relief.',
    },
    {
        Id: '29',
        sImg: sImg3,
        icon: CommunityCare,
        title: 'Community of Care',
        slug: 'Build-Home',
        description: 'Connect with like-minded individuals through forums, support groups and mentorship programmes. Build strength with encouragement from those who understand.',
    },
    {
        Id: '30',
        sImg: sImg3,
        icon: Workshop,
        title: 'Expert-Led Workshops',
        slug: 'Build-Home',
        description: 'Participate in mindfulness retreats, stress management webinars and therapeutic art sessions, all led by mental health professionals.',
    },
    {
        Id: '31',
        sImg: sImg3,
        icon: Resources,
        title: 'Vent Freely, No Judgment',
        slug: 'Build-Home',
        description: 'Express yourself freely in a safe, judgment-free space. Connect with a supportive listener or peer group - no account needed.',
    },
    {
        Id: '32',
        sImg: sImg3,
        icon: PrivateSupport,
        title: 'Private Support',
        slug: 'Build-Home',
        description: 'Chat one-on-one with Willful Listeners - empathetic volunteers ready to listen. Prefer to talk? Schedule a call at your convenience.',
    },
    {
        Id: '33',
        sImg: sImg3,
        icon: HelpTools,
        title: 'Self-Help Tools',
        slug: 'Build-Home',
        description: 'Access expert-backed articles, guides and tips on stress relief, mindfulness and emotional resilience. Download practical exercises for daily support.',
    },
    {
        Id: '34',
        sImg: sImg3,
        icon: SupportHelp,
        title: 'Crisis Help',
        slug: 'Build-Home',
        description: 'Get immediate access to trusted helplines and professional support services whenever you need them. You’re not alone.',
    },
]

const MindUpload = (props) => {



    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Mind Unload'} pagesub={'Our Initiatives'} bg={Breadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Mind Unload" title="Your Gateway to Mental Wellness" img={AboutUs} para={<> Mind Unload is one of Jainam Jivika Foundation's key initiatives designed to help you prioritise your mental well-being. Here, you can relax, reduce stress and find support from a compassionate community. With expert guidance and engaging activities, Mind Unload is your trusted partner on the path to a healthier and happier mind. </>} />
                </div>

                <div className='gray-bg pb-40' id='feature'>
                    <About4 subtitle="Unwind Your Mind, Embrace Your Peace" title="About Mind Unload" para="In today’s fast-paced world, stress and mental challenges are common, but Mind Unload offers a safe space where individuals can find balance, peace and support. At Jainam Jivika Foundation, we believe that mental wellness is as important as physical health. That’s why Mind Unload is designed to make self-care accessible, ensuring that everyone has the resources they need to lead a calmer, more fulfilling life." button={false} icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} title1="Anonymous Venting" title2="One-on-One Conversations" des1="Chat anonymously with a supportive listener or peer group - no account needed for basic venting sessions." des2="Connect with Willful Listeners - empathetic, non-judgmental volunteers." des3="Access articles, tips and guides for emotional well-being." des4="Get direct access to helplines for immediate support." title3="Self-Help Resources" title4="Crisis Support Links" />
                </div>

                <div className='white-bg'>
                    <ServiceSection subtitle="About the Initiative" title="Why Choose Mind Unload?" data={mindUnloadFeatures} startIndex="26" endIndex="31" naviagtion={true} />
                </div>

                {/* <div className='gray-bg'>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="Discover the Power of Mind Unload" title="What Awaits You at Mind Unload?" Features2={Features2} ctabutton={false} button="KNOW MORE" className='mind-unload-div-respo' />
                </div> */}

                <div className='pt-40 gray-bg'>
                    <CtaSection subtitle="Discover Peace, One Step at a Time" title="Start Your Journey to Wellness" para="Your path to better mental health begins here. Whether you’re feeling overwhelmed, stressed or simply need someone to listen, we provide a supportive space where your voice matters." ctaLink="https://mindunload.mini91.com/" button="Start Your Journey Today" img={HomeCtaHero} img1={HomeVolunteerHero1} img2={HomeVolunteerHero2} img3={HomeVolunteerHero3} img4={HomeVolunteerHero4} img5={HomeVolunteerHero5} />
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default MindUpload;
