import React, { Fragment} from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Breadcumb from '../../images/flood-relief/breadcumb.jpg'
import q1 from '../../images/flood-relief/1.jpg'
import q2 from '../../images/flood-relief/2.jpg'
import q3 from '../../images/flood-relief/3.jpg'
import q4 from '../../images/flood-relief/4.jpg'
import q5 from '../../images/flood-relief/5.jpg'

const FloodReliefMaterial = (props) => {

 

   

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Flood Relief Material'} pagesub={'Gallery'} bg={Breadcumb} />

         
                <section className="blog pt-60">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <div className="sectionTitle mb-40" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>Flood Relief Material
                                    </span>
                                    <h2 className="sectionTitle__big text-center">Flood Relief Material</h2>
                                    <p className='text-center' style={{ fontSize: '24px' }}>Powerful images of our relief efforts during challenging times, delivering hope and essentials.                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-40">
                                    <figure className="blogBlock__figure">
                                        {/* <Link to="#" */}
                                        {/* onClick={(e) => e.preventDefault()} className="blogBlock__figure__link"> */}
                                        <img src={q1} alt="Stronger Together" className="blogBlock__figure__image" />
                                        {/* </Link> */}
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q2} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q3} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q4} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q5} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default FloodReliefMaterial;
