import React, { Fragment } from "react";
import Header from "../components/header/Header";
import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";
import Scrollbar from "../components/scrollbar/scrollbar";
import Legal1 from "../images/docs/legal-1.png";
import Legal2 from "../images/docs/legal-2.png";
import Legal3 from "../images/docs/legal-3.png";
import Legal4 from "../images/docs/legal-4.png";
import Legal5 from "../images/docs/legal-5.png";
import Legal6 from "../images/docs/legal-6.png";
import Legal7 from "../images/docs/legal-7.png";
import Breadcumb from "../images/docs/documnetimg.jpg"

// import Legal6 from '../documents/12aa certificate.pdf'
const LegalDocuments = (props) => {
 

  return (
    <Fragment>
      <Header hclass={"header--styleFour"} />
      <main className="page_content">
        <PageTitle pageTitle={"Legal Documents"} pagesub={"Legal Documents"} bg={Breadcumb}  />

        <section className="about pt-60">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sectionTitle text-center mb-30" id="feature">
                  <span className="sectionTitle__small justify-content-center">
                    <i className="fa-solid fa-heart btn__icon"></i>Legal Documents
                  </span>
                  <h2 className="sectionTitle__big">Legal Documents</h2>
                </div>
              </div>
              <section className="pt-40">
                <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* <div class="row gap no-top resppppp-legal-docs"> */}
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/12aa-certificate.pdf" target="_blank" rel="noopener noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal1} alt="Legal1" />
                        </i>
                        <h5 style={{ textAlign: "center" }} className="w-100">
                          12AA Certificate
                        </h5>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/80g-certificate.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal2} alt="Legal2" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}>80G Certificate</h5>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/pan-card.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal6} alt="Legal6" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}>Pan Card</h5>
                      </div>
                    </a>
                  </div>
                  {/* </div> */}
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/certificate-of-incorporation.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal4} alt="Legal4" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}> Certificate of Incorporation</h5>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/memorandum-of-association.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal5} alt="Legal5" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}>Memorandum of Association</h5>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/article-of-association.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal3} alt="Legal3" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}>Article of Association</h5>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-md-6" style={{ paddingBottom: 40 }}>
                    <a href="/documents/jainam-jivika-foundation-approval-letter-CSR1.pdf" target="_blank" rel="noreferrer">
                      <div className="get-involved data contact" style={{ height: 200 }}>
                        <i>
                          <img src={Legal7} alt="Legal3" />
                        </i>
                        <h5 style={{ textAlign: "center !important" }}>CSR Registration Approval</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default LegalDocuments;
