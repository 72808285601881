import React, { Fragment } from 'react';
// import Breadcumb from '../../images/contact/breadcumb.jpg'
import Header from '../components/header/Header';
import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer/Footer';
import Scrollbar from '../components/scrollbar/scrollbar';
import Breadcumb from "../images/portfolio/privacy.jpg"
// import Scrollbar from '../components/scrollbar/scrollbar';


const PrivacyPolicy = (props) => {

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Privacy Policy'} pagesub={'Privacy Policy'} bg={Breadcumb} />
                {/* <ContactSection /> */}
                <section className="about pt-60 pb-20 policiess" style={{ paddingTop: '60px' }}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12">
                                <div className="sectionTitle text-center mb-30" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Privacy Policy
                                    </span>
                                    <h2 className="sectionTitle__big">Privacy Policy</h2>
                                </div>
                            </div> */}
                            <div className='mt-4'>
                                <ol className="privacy-policy-intro-list">
                                    <li><strong>Company Name:</strong> Jainam Jivika Foundation (referred to as “Company”)</li>
                                    <li><strong>Company Website:</strong> <a href="https://jjfindia.com/" target="_blank" rel="noreferrer">JJFIndia.com </a></li>
                                    <li><strong>Company Email:</strong> <a href="mailto:info@JJFIndia.com" target="_blank" rel="noreferrer">info@JJFIndia.com</a></li>
                                    <li><strong>Company Contact No.:</strong> <a href="tel:+919552895289" target="_blank" rel="noreferrer">+91 95 5289 5289</a></li>
                                    <li><strong>Registered Address:</strong> <a href="https://maps.app.goo.gl/mCvV6mqmRgEaRtLL6" target="_blank" rel="noreferrer">Jainam Jivika Foundation <br />
                                        3, Jainam Tower, 3, Sai Park, <br />
                                        Belthika Nagar, Thergaon (Chinchwad), <br />
                                        Pune - 411033, Maharashtra, India</a></li>
                                    <li><strong>Registered Country:</strong> India</li>
                                </ol>


                                <ol className="privacy-policy-intro-list2 policy-list-items mt-4">
                                    <li>
                                        <h4>What Information Do We Collect? </h4>
                                        <div>
                                            <p>  We collect personal information that you voluntarily provide when registering, expressing interest in our Services or contacting us. This may include:</p>
                                            <ul className="unordered-listtt listtt" style={{paddingLeft:'20px'}}>
                                                <li>Names</li>
                                                <li>Contact Numbers</li>
                                                <li>Email addresses</li>
                                                <li>Mailing addresses</li>
                                                <li>Job titles</li>
                                                <li>Usernames</li>
                                                <li>Contact Preferences</li>
                                                <li>Contact or authentication data</li>
                                                <li>Billing addresses</li>
                                                <li>Payment card information</li>
                                                <li>Passwords</li>
                                            </ul>

                                            <ul className="policy-section-list">
                                                <li>
                                                    <h5>Sensitive Information</h5>
                                                    <p className="">We do not process sensitive personal information such as government IDs, financial account information, health information or biometric data.</p>
                                                </li>
                                                <li>
                                                    <h5>Social Media Login Data</h5>
                                                    <p className="">If you register using a social media account (e.g., Facebook or Twitter), we may collect information as described in the ‘How Do We Handle Your Social Logins section’.</p>
                                                </li>
                                                <li>
                                                    <h5>Information Automatically Collected</h5>
                                                    <p className="">We automatically collect certain information when you visit or use our Services, such as your IP address, browser type, device information and usage data to maintain the security and functionality of our Services.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>How Do We Process Your Information?</h4>
                                        <div className="policies-section">
                                            <p>We process your information to provide and improve our Services, manage user accounts, respond to inquiries, send administrative information, fulfil orders, request feedback and send marketing communications. Processing may also include fraud prevention, targeted advertising and complying with legal obligations.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>When and With Whom Do We Share Your Personal Information?</h4>
                                        <div className="policies-section">
                                            <p>We may share information with third parties in specific situations, such as:</p>
                                            <ul className="unordered-listtt listtt">
                                                <li>Service Providers for performing services on our behalf.</li>
                                                <li>Affiliates to honour this Privacy Notice.</li>
                                                <li>Business Partners for co-marketing purposes.</li>
                                                <li>Legal Compliance and Security when required by law or to protect the Company’s rights.</li>
                                                <li>Merger, Sale or Other Asset Transfers if part of a business transition.</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Do We Use Cookies and Other Tracking Technologies?</h4>
                                        <div className="policies-section">
                                            <p>We may use cookies and similar technologies to collect and store information. For more details, see our Cookie Policy.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>How Do We Handle Your Social Logins?</h4>
                                        <div className="policies-section">
                                            <p>If you choose to register or log in using a social media account, we may access your profile information as permitted by the provider. We use this information to provide and improve our Services. We encourage you to review your social media provider’s privacy policy for more details.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>How Long Do We Keep Your Information?</h4>
                                        <div className="policies-section">
                                            <p>We retain personal information only as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law. After that, we securely delete or anonymise the information.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>How Do We Keep Your Information Safe?</h4>
                                        <div className="policies-section">
                                            <p>We use organisational and technical security measures to protect your information. However, no system is entirely secure and the transmission of information over the internet carries inherent risks.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>What are Your Privacy Rights?</h4>
                                        <div className="policies-section">
                                            <p>Depending on your location, you may have the right to access, correct or delete your personal information. You may also opt out of marketing communications or withdraw consent for processing where applicable.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Account Information</h4>
                                        <div className="policies-section">
                                            <p>Please contact us to review, update or delete your account information. Upon request, we will deactivate or delete your account from active databases, although some information may be retained to prevent fraud or comply with legal obligations.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Controls for Do-Not-Track Features</h4>
                                        <div className="policies-section">
                                            <p>Currently, we do not respond to Do-Not-Track (DNT) signals due to the lack of a standardised DNT technology protocol.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <h4>Do We Make Updates to this Notice?</h4>
                                        <div className="policies-section">
                                            <p>Yes, we may update this Privacy Notice as needed to comply with relevant laws. The revised version will be effective as soon as it is accessible. If we make significant changes, we may notify you.</p>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default PrivacyPolicy;