import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Testimonial from '../../components/Testimonial/Testimonial';
import male from '../../images/auth/male.png'
import Breadcumb from '../../images/testimonials/breadcumb.jpg'
import MainCta from '../../images/testimonials/main-cta-1.jpg'
import TestimonialImg2 from '../../images/home/testimonial-home-2.jpg'
import TestimonialImg3 from '../../images/home/testimonial-home-3.jpg'
import Img1 from '../../images/testimonials/img-1.jpg'
import Img2 from '../../images/testimonials/img-2.jpg'


const testimonial = [
    {
        id: '01',
        tImg: male,
        Title: 'Parent Beneficiary',
        Des: 'Thanks to Jainam Jivika Foundation, my children now have access to education they never dreamed of before. The volunteers brought books, set up digital learning tools and even organised sessions to mentor the students. My kids are now more confident, curious and excited about their future. Jainam Jivika Foundation hasn’t just improved their education - it has changed their lives and given us hope for a brighter tomorrow.',
        Name: 'Parent Beneficiary',
        sub: 'Director of Marketing',
    },
    {
        id: '02',
        tImg: male,
        Title: 'Disaster Relief Beneficiary',
        Des: 'During the floods in our area, we were left with nothing - no food, no shelter and no one to turn to. That’s when Jainam Jivika Foundation stepped in with relief supplies and immediate support. They didn’t just bring material help; they brought reassurance that we were not alone. Their kindness and quick action saved our family from despair and we are forever grateful for their compassion.',
        Name: 'Disaster Relief Beneficiary',
        sub: 'Tech Startup Founder',
    },
    {
        id: '03',
        tImg: male,
        Title: 'Volunteer',
        Des: 'Volunteering with Jainam Jivika Foundation has been one of the most rewarding experiences of my life. I’ve helped teach kids in underprivileged areas, supported blood donation drives and even participated in tree plantation programmes. Each initiative showed me how small efforts can lead to big changes. Seeing the smiles on people’s faces and knowing I’ve contributed to their happiness is the best feeling ever.',
        Name: 'Volunteer',
        sub: 'Monprofit Director',
    },
    {
        id: '04',
        tImg: male,
        Title: ' Eye Donation Recipient',
        Des: 'The Akshay Netra initiative is nothing short of a miracle for people like me. Losing my eyesight felt like losing my independence. But thanks to Jainam Jivika Foundation and the donors, I underwent a successful corneal transplant. Today, I can see my family, work again and live my life with dignity. Their support in every step of the way made this journey easier and I will always remember their kindness.',
        Name: ' Eye Donation Recipient',
        sub: 'Monprofit Director',
    },
    {
        id: '04',
        tImg: male,
        Title: 'Mental Health Programme Participant',
        Des: 'As a college student, I was under immense pressure, juggling academics and personal challenges. Mind Unload’s mindfulness workshops helped me find balance and clarity. The techniques I learned there have changed how I handle stress. I feel more in control of my emotions and decisions and my performance in my studies has improved drastically. Jainam Jivika Foundation is truly helping people like me achieve mental well-being.',
        Name: 'Mental Health Programme Participant',
        sub: 'Monprofit Director',
    },
    {
        id: '05',
        tImg: male,
        Title: 'Youth Programme Participant',
        Des: 'Being a part of Young Jains was a life-changing experience. The workshops on Jain values, non-violence and mindfulness helped me connect with my cultural roots in a way I never had before. It inspired me to start my own project promoting kindness and sustainability in my community. Jainam Jivika Foundation has given me a purpose and I’m proud to be part of this journey.',
        Name: 'Youth Programme Participant',
        sub: 'Monprofit Director',
    },
    {
        id: '06',
        tImg: male,
        Title: 'NGO Partner',
        Des: 'Our NGO was struggling to find volunteers for an education drive, but Jainam Jivika Foundation’s India’s Sevak Army connected us with amazing individuals who were eager to help. With their support, we completed the project in half the expected time and reached over 300 children. Their platform is a blessing for NGOs like ours and their dedication to making a difference is inspiring.',
        Name: 'NGO Partner',
        sub: 'Monprofit Director',
    },
]

const Testimonials = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Testimonials'} pagesub={'Impacts & Stories'} bg = {Breadcumb} />


                <div className='pt-40 pb-20' id='feature'>
                    <Testimonial testimonial={testimonial} img1={Img2} img2={TestimonialImg2} img3={TestimonialImg3} img4={Img1} subtitle="TESTIMONIALS" title="Voices that Reflect Impact and Gratitude" para="At Jainam Jivika Foundation, our mission is to transform lives through compassion, empowerment and collaboration. These heartfelt testimonials from beneficiaries, volunteers and supporters showcase the meaningful changes our initiatives bring to individuals and communities." fontSize="26px" />
                </div>


                <div className="volunteer pt-40 pb-40 gray-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 pb-20 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={MainCta} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Join the change
                                        </span>
                                        <h2 className="sectionTitle__big">Be a Part of the Impact</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{textAlign:'justify'}}>
                                        These testimonials are just a glimpse of the transformative work Jainam Jivika Foundation is doing. Every story reflects the power of kindness, collaboration and hope. Join us to create more such inspiring journeys and make a lasting difference.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <Link onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" to="/contact-us">
                                            <span className="btn__text">Join Us Today</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>

            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Testimonials;
