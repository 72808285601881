import React, { Fragment } from 'react';
import Breadcumb from "../../images/about/social-media.jpg"
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const PDFs = (props) => {


    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'PDFs'} pagesub={'PDFs'} bg={Breadcumb} />


                <section className="volunteersSection pb-120 pt-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-70" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Explore, Learn and Share for a Better Tomorrow
                                    </span>
                                    <h2 className="sectionTitle__big">Empowering Change Through Knowledge</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="aboutContent__buttons" style={{ width: 'fit-content' }}>
                                <a className="btn btn--styleOne btn--primary it-btn" target='_blank' rel="noopener noreferrer" href='/pdfs/jainam-jivika-foundation-profile.pdf'>
                                    <span className="btn__text">Jainam Jivika Foundation Profile</span>
                                    <i className="fa-solid fa-heart btn__icon"></i>
                                    <span className="it-btn__inner">
                                        <span className="it-btn__blobs">
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                        </span>
                                    </span>
                                    <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter>
                                                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                </feGaussianBlur>
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                </feColorMatrix>
                                                <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default PDFs;
