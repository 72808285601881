import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import BloodDonation1 from '../../images/blood-donation/blood-donation-1.png'
import BloodDonation2 from '../../images/blood-donation/blood-donation-2.png'
import BloodDonation3 from '../../images/blood-donation/blood-donation-3.png'
import BloodDonation4 from '../../images/blood-donation/blood-donation-4.png'
import BloodDonation5 from '../../images/blood-donation/blood-donation-5.png'
import BloodDonation6 from '../../images/blood-donation/blood-donation-6.png'
import Breadcumb from '../../images/blood-donation/breadcumb.jpg'


const BloodDonation = (props) => {

 

    

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Blood Donation'} pagesub={'Gallery'} bg={Breadcumb} />

                {/* <div className='mt-5 pt-4 pb-100'>
                    <About2 />
                </div> */}

                <section className="blog pt-60 ">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <div className="sectionTitle mb-40" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>Blood Donation
                                    </span>
                                    <h2 className="sectionTitle__big text-center">Blood Donation</h2>
                                    <p className='text-center' style={{ fontSize: '24px' }}>Snapshots of life-saving moments during our blood donation drives.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        {/* <Link to="#" */}
                                        {/* onClick={(e) => e.preventDefault()} className="blogBlock__figure__link"> */}
                                        <img src={BloodDonation1} alt="Stronger Together" className="blogBlock__figure__image" />
                                        {/* </Link> */}
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={BloodDonation2} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={BloodDonation3} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={BloodDonation4} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={BloodDonation5} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={BloodDonation6} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default BloodDonation;
