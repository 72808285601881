import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import { Fade, Slide } from "react-awesome-reveal";
import map from '../../images/shapes/map.png'
import DonationListSection from '../DonationListSection/DonationListSection';



const FeaturesSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <div className="featureArea pt-0">
            <div className="featureArea__map">
                <img src={map} alt="Join the Movement" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="keyFeatureBox pb-40 ">
                            <div className="row">
                                {props.Features.map((features, fitem) => (
                                    <div className="col-lg-4 wow animate__fadeInLeft" key={fitem}>
                                        <Slide direction='left' triggerOnce={'false'} className={``} duration={features.duration}>
                                            <div>
                                                <div className={`keyFeatureBlock mb-30 ${props.height}`}>
                                                    <div className="keyFeatureBlock__left">
                                                        <span className="keyFeatureBlock__icon">
                                                            <img src={features.icon} width={50} alt="Hope in Action" />
                                                        </span>
                                                    </div>
                                                    <div className="keyFeatureBlock__content">
                                                        <h3 className="keyFeatureBlock__heading">
                                                            <div className="keyFeatureBlock__heading__link" to="/services">
                                                                {features.title}
                                                            </div>
                                                        </h3>
                                                        <p className="keyFeatureBlock__text" style={{fontSize:'16px', textAlign:'justify'}}>{features.des}</p>
                                                    </div>
                                                    <div className="keyFeatureBlock__skill skill-bar" style={{ width: features.width }}>
                                                        <span className="keyFeatureBlock__skill__bar"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slide>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="donnerArea pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="donnerAreaContent text-center mb-0">
                                <Fade direction='up' triggerOnce={'false'} duration={1200}>
                                    <div>
                                        <h2 className="donnerAreaContent__bigTitle wow animate__fadeInUp" data-wow-duration="1200ms" data-wow-delay="200ms">
                                            <span className="donnerAreaContent__bigTitle__number">{props.count}</span>
                                            <span className="donnerAreaContent__bigTitle__text">{props.range}</span>
                                        </h2>
                                    </div>
                                </Fade>
                                <Fade direction='up' triggerOnce={'false'} duration={1400}>
                                    <div>
                                        <h3 className="donnerAreaContent__heading text-uppercase wow animate__fadeInUp" data-wow-duration="1200ms" data-wow-delay="300ms">{props.work}</h3>
                                    </div>
                                </Fade>
                                <Fade direction='up' triggerOnce={'false'} duration={1600}>
                                    <div>
                                        <Link to='/contact-us' onClick={ClickHandler} className="btn btn--styleOne btn--black it-btn wow animate__fadeInUp" data-wow-duration="1200ms" data-wow-delay="400ms">
                                            <span className="btn__text">{props.button}</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </Link>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { props.campaigns && <DonationListSection />}
        </div>
    );
}

export default FeaturesSection;