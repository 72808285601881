import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';


const MissionVision = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (

        <div className="mvv pt-20 pb-20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="mvvTabs">
                            <div className="mvvTabs__wrapper d-flex align-items-start">
                                <div className="nav nav-pills mb-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <Nav tabs className="nav justify-content-center">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggle('1'); }}
                                            >
                                                Our MIssion
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggle('2'); }}
                                            >
                                                Our Vision
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { toggle('3'); }}
                                            >

                                                Our Values
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                <TabContent activeTab={activeTab} className="mb-0">
                                    <TabPane tabId="1">
                                        <div className="mvvTabs__content">
                                            <h2 className="mvvTabs__heading"> Our Mission </h2>
                                            <p className="mvvTabs__text mb-0 mission-para" style={{textAlign:'justify'}}>At our core, we are a social foundation dedicated to empowering individuals, inspiring community support and creating lasting impact. We address key areas like social welfare, environmental protection initiatives, mental health support and cultural preservation to bring about meaningful change. </p>

                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="mvvTabs__content">
                                            <h2 className="mvvTabs__heading">Our Vision</h2>
                                            <p className="mvvTabs__text mb-0 mission-para" style={{textAlign:'justify'}}>We dream of a world where everyone is respected, has equal opportunities and works together for a brighter and kinder future. By promoting sustainable development, community health support and well-being, we aim to create a more inclusive and supportive society.</p>

                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="mvvTabs__content">
                                            <h2 className="mvvTabs__heading">Our Values</h2>
                                            <p className="mvvTabs__text mb-0 mission-para wrap-text">
                                                <ul>
                                                    <li>
                                                        <h6>Compassion</h6>
                                                        <p className='wrap-text' style={{textAlign:'justify'}}>We believe in supporting those in need with kindness and empathy, helping to uplift our communities.</p>
                                                    </li>
                                                    <li>
                                                        <h6>Action</h6>
                                                        <p className='wrap-text' style={{textAlign:'justify'}}>Taking meaningful steps to address community health, promote green initiatives and create lasting social impact.</p>
                                                    </li>
                                                    <li>
                                                        <h6>Inclusivity</h6>
                                                        <p className='wrap-text' style={{textAlign:'justify'}}>Ensuring everyone has a voice and feels valued as part of a larger effort for social welfare and progress. </p>
                                                    </li>
                                                    <li>
                                                        <h6>Collaboration</h6>
                                                        <p className='wrap-text' style={{textAlign:'justify'}}>By working together, we strengthen community support and empower individuals to build a better future for all.</p>
                                                    </li>
                                                </ul>
                                            </p>

                                            {/* <div className="mvvTabs__skills">
                                                <div className="mvvTabs__skills__block mb-20">
                                                    <h4 className="mvvTabs__skills__counter"><span className="mvvTabs__skills__counter">65</span>%</h4>
                                                    <span className="mvvTabs__skills__title color-title">kids need help</span>
                                                </div>
                                                <div className="mvvTabs__skills__block mb-20">
                                                    <h4 className="mvvTabs__skills__counter">$<span className="mvvTabs__skills__counter">190</span>k</h4>
                                                    <span className="mvvTabs__skills__title color-title">raised Now</span>
                                                </div>
                                                <div className="mvvTabs__skills__block mb-20">
                                                    <h4 className="mvvTabs__skills__counter"><span className="mvvTabs__skills__counter">220</span>+</h4>
                                                    <span className="mvvTabs__skills__title color-title">Voleenteer</span>
                                                </div>
                                            </div>
                                            <p className="mvvTabs__text mb-0">There are many variations of passages of Lorem Ipsum available, but
                                                the
                                                majority have suffered alteration in some form,
                                                by injected humour.</p> */}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionVision;