import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import { Link } from 'react-router-dom';
import About3 from '../../components/about3/about3';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Breadcumb from '../../images/volunteer-opportunities/breadcumb.jpg'
import AboutUs from '../../images/volunteer-opportunities/about-us.jpg'
import Volunteer from '../../images/volunteer-opportunities/tree-plantation.jpg'
import Volunteer2 from '../../images/volunteer-opportunities/volunteer-2.jpg'
import Volunteer3 from '../../images/volunteer-opportunities/donation-drives.jpg'
import Volunteer4 from '../../images/volunteer-opportunities/online-volunteering.jpg'
import Volunteer5 from '../../images/volunteer-opportunities/volunteer-5.jpg'
import Flexibility from '../../images/volunteer-opportunities/flexibility.png'
import SkillDevelopment from '../../images/volunteer-opportunities/fullfillment.png'
import Networking from '../../images/volunteer-opportunities/networking.png'
import Fullfillment from '../../images/volunteer-opportunities/skill-improvement.png'
import Opportunities from '../../images/volunteer-opportunities/opportunity.png'
import SignUp from '../../images/volunteer-opportunities/signup.png'
import Matched from '../../images/volunteer-opportunities/puzzle.png'
import Volunteering from '../../images/volunteer-opportunities/volunteering.png'
import MainCta from '../../images/volunteer-opportunities/main-cta.jpg'


const Features = [
    {
        title: 'Browse Opportunities',
        des: 'Explore our wide range of options that align with your interests and expertise.',
        icon: Opportunities,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Sign Up',
        des: 'Fill out the online volunteer form and let us know how you’d like to contribute.',
        icon: SignUp,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Get Matched',
        des: 'We’ll connect you with projects and initiatives where your time and skills will have the greatest impact.',
        icon: Matched,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Start Volunteering ',
        des: 'Begin your journey with guidance and support from Jainam Jivika Foundation team.',
        icon: Volunteering,
        width: '70%',
        duration: 1400,
    },
]

const Features2 = [
    {
        title: 'Flexibility',
        des: 'Volunteer on your schedule: once a week, once a month or for a specific event.',
        icon: Flexibility,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Skill Development ',
        des: 'Gain valuable experience in leadership, teamwork and project management.',
        icon: SkillDevelopment,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Networking',
        des: 'Connect with passionate individuals, NGOs and organisations sharing a common goal of creating change.',
        icon: Networking,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Fulfilment',
        des: 'Make a tangible impact on people’s lives while being part of a cause greater than yourself.',
        icon: Fullfillment,
        width: '70%',
        duration: 1400,
    },
]

const VolunteerApportunities = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Volunteer Opportunities'} pagesub={'Join Us'} bg={Breadcumb} />
                <div className='pt-40 pb-40'>
                    <About2 subtitle="Volunteer Opportunities with Jainam Jivika Foundation" img={AboutUs} title="Your Time, Skills and Passion Can Create Lasting Change" para="At Jainam Jivika Foundation, we believe everyone has something valuable to contribute. Our volunteer opportunities are designed to match your interests, skills and availability, allowing you to make a meaningful impact in the community. Whether you’re a student, working professional, homemaker or retiree, there’s a way for you to get involved and create positive change." />
                </div>


                <section className="events white-bg pt-40 pb-40" id='feature'>
                    <div className="container">
                        <div className="sectionTitle mb-40 w-100 d-flex flex-column justify-content-center align-items-center">
                            <span className="sectionTitle__small text-center">
                                <i className="fa-solid fa-heart btn__icon"></i>
                                Join Hands, Share Skills, Create Change
                            </span>
                            <h2 className="sectionTitle__big text-center">Explore Our Volunteer Opportunities</h2>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Volunteer5} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Skill-Based Volunteering  </a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <h6 className='pb-20 wrap-text'>Use your expertise to make a difference.</h6>
                                            <ul className='listtt' style={{ paddingLeft: "20px" }}>
                                                <li>
                                                    {/* <span className="eventsBlock__meta__title">What it Does :</span> */}
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}>Teach children or mentor young adults.</span>
                                                </li>
                                                <li>
                                                    {/* <span className="eventsBlock__meta__title">Milestones :</span> */}
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Help with event planning or campaign organisation.</span>
                                                </li>
                                                <li>
                                                    {/* <span className="eventsBlock__meta__title">Vision :</span> */}
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Provide professional skills like graphic design, writing or legal aid. </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Volunteer} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a> One-Time Volunteering </a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <h6 className='pb-20 wrap-text'>Perfect for those with limited time.</h6>
                                            <ul className='listtt' style={{ paddingLeft: "20px" }}>
                                                <li>
                                                    {/* <span className="eventsBlock__meta__title">What it Does :</span> */}
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}>Participate in tree plantation drives, blood donation camps or relief material distribution.</span>
                                                </li>
                                                <li>
                                                    {/* <span className="eventsBlock__meta__title">Milestones :</span> */}
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Join awareness walks, clean-up drives or school activities.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Volunteer2} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Ongoing Volunteering</a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <h6 className='pb-20 wrap-text'>Be part of a long-term project.</h6>
                                            <ul className='listtt' style={{ paddingLeft: "20px" }}>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Volunteer with our education or healthcare programmes. </span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Support cultural preservation initiatives like Young Jains.</span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}>Be a regular contributor to disaster relief and rehabilitation efforts. </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Volunteer4} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Virtual Volunteering  </a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <h6 className='pb-20 wrap-text'>Contribute from the comfort of your home.</h6>
                                            <ul className='listtt' style={{ paddingLeft: "20px" }}>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Spread awareness about our initiatives on social media. </span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Assist in creating content or fundraising campaigns.</span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Offer administrative support like data entry or online research. </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Volunteer3} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Community Volunteering </a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <h6 className='pb-20 wrap-text'>Work directly with local communities.</h6>
                                            <ul className='listtt' style={{ paddingLeft: "20px" }}>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Help organise donation drives for clothes, food or school supplies. </span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Provide support to differently-abled individuals or underprivileged families.</span>
                                                </li>
                                                <li>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Assist NGOs in executing their programmes effectively. </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className='gray-bg'>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="Grow, Connect and Make a Difference" title="Benefits of Volunteering with Jainam Jivika Foundation" className="respo-volunteer-opportunities" Features2={Features2} ctabutton={false} button="KNOW MORE" />
                </div>

                <div className=''>
                    <About3 bg="" ctaSection={false} subtitle="Step In, Stand Out, Make an Impact" title="How to Get Started?" Features2={Features} ctabutton={false} className="volunteer-opportunities-get-started" button="KNOW MORE" />
                </div>

                <div className='gray-bg'>
                    <ServiceSection subtitle="Be the Change, One Event at a Time" className="upcoming-volunteer-events" center={true} title="Upcoming Volunteer Events" startIndex="45" endIndex="49" />
                </div>

                <div className='pt-40 white-bg pb-40'>
                    <div className="volunteer">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 mb-10">
                                    <div className="volunteerUser">
                                        <div className="volunteerUser__box">
                                            <div className="volunteerUser__thumb">
                                                <img src={MainCta} alt="Join Us and Make a Difference" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-30">
                                    <div className="aboutContent aboutContent--style3">
                                        <div className="sectionTitle">
                                            <span className="sectionTitle__small">
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                Together We Can, Together We Will
                                            </span>
                                            <h2 className="sectionTitle__big">Join Jainam Jivika Foundation Volunteer Family</h2>
                                        </div>
                                        <p className="aboutContent__text">
                                            Every step you take as a volunteer helps uplift communities, empower individuals and create hope for a better tomorrow. Explore our opportunities and become a part of the change you wish to see.
                                        </p>
                                        <div className="aboutContent__buttons">
                                            <Link to='/contact-us' onClick={ClickHandler} className="btn btn--styleOne btn--secondary it-btn">
                                                <span className="btn__text">Sign Up to Volunteer Today</span>
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                <span className="it-btn__inner">
                                                    <span className="it-btn__blobs">
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                    </span>
                                                </span>
                                                <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                    <defs>
                                                        <filter>
                                                            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                            </feGaussianBlur>
                                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                            </feColorMatrix>
                                                            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                        </filter>
                                                    </defs>
                                                </svg>
                                            </Link>
                                            <Link onClick={ClickHandler} to="/volunteer-opportunities" className="btn btn--styleOne btn--primary it-btn">
                                                <span className="btn__text">Explore Opportunities Now</span>
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                <span className="it-btn__inner">
                                                    <span className="it-btn__blobs">
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                    </span>
                                                </span>
                                                <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                    <defs>
                                                        <filter>
                                                            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                            </feGaussianBlur>
                                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                            </feColorMatrix>
                                                            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                        </filter>
                                                    </defs>
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Testimonial/> */}


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default VolunteerApportunities;
