import React, { Fragment, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Breadcumb from '../../images/about/who-we-are-breadcumb.jpg';
import OurVision from '../../images/about/who-we-are-vision.jpg';
import OurMission from '../../images/about/who-we-are-mission.jpg';
import OurValues from '../../images/about/who-we-are-values.jpg';
import BackgroundImg from '../../images/about/about-us-bg.jpg';


const WhoWeAre = (props) => {
    
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Who We Are?'} pagesub={'About Us'} bg={Breadcumb} />

                <div className=''>

                    <div className="about position-relative">
                        <img src={BackgroundImg} alt="Join the Movement" className="map-about-tab h-100 w-100" />
                        <div className="container pt-40 pb-40">
                            <div className="row">
                                <div className="col-12">
                                    <div className="featureTab">
                                        <Nav tabs className="nav justify-content-center">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggle('1'); }}
                                                >
                                                    Our Mission
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggle('2'); }}
                                                >
                                                    Our Vision
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => { toggle('3'); }}
                                                >

                                                    Our Values
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="pt-55">
                                            <TabPane tabId="1">
                                                <div className="row">
                                                    <div className="col-lg-10 mx-auto">
                                                        <div className="aboutDetails text-center">
                                                            <p className="aboutDetailsText mb-20" style={{fontSize:'24px'}}>At our core, we are a social foundation dedicated to empowering individuals, inspiring community support and creating lasting impact. We address key areas like social welfare, environmental protection initiatives, mental health support and cultural preservation to bring about meaningful change.
                                                            </p>
                                                        </div>
                                                        <div className="aboutDetailsThumbs pt-20">
                                                            <div className="row g-0 align-items-center justify-content-center responsive-images">
                                                                <div className="col-lg-4">
                                                                    <div className="aboutDetailsThumb">
                                                                        <img src={OurMission} alt="About Join the Movement" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="row">
                                                    <div className="col-lg-10 mx-auto">
                                                        <div className="aboutDetails text-center">
                                                            <p className="aboutDetailsText mb-20" style={{fontSize:'24px'}}>
                                                                We dream of a world where everyone is respected, has equal opportunities and works together for a brighter and kinder future. By promoting sustainable development, community health support and well-being, we aim to create a more inclusive and supportive society.
                                                            </p>
                                                        </div>
                                                        <div className="aboutDetailsThumbs pt-20">
                                                        <div className="row g-0 align-items-center justify-content-center responsive-images">
                                                                <div className="col-lg-4">
                                                                    <div className="aboutDetailsThumb">
                                                                        <img src={OurVision} alt="About Join the Movement" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div className="row">
                                                    <div className="col-lg-10 mx-auto">
                                                        <div className="aboutDetails text-center">
                                                            <p className="aboutDetailsText mb-20">
                                                                <ul className='d-flex justify-content-start align-items-center flex-column'>
                                                                    <li className='text-left'>
                                                                        <h6 className='px-2' style={{fontSize:'24px'}}>Compassion</h6>
                                                                        <p className='wrap-text' style={{fontSize:'20px'}}> We believe in supporting those in need with kindness and empathy, helping to uplift our communities.</p>
                                                                    </li>   
                                                                    <li className='justify-content-center'>
                                                                        <h6 className='px-2' style={{fontSize:'24px'}}>Action </h6>
                                                                        <p className='wrap-text' style={{fontSize:'20px'}}>Taking meaningful steps to address community health, promote green initiatives and create lasting social impact.</p>
                                                                    </li>
                                                                    <li className=' justify-content-center'>
                                                                        <h6 className='px-2' style={{fontSize:'24px'}}>Inclusivity </h6>
                                                                        <p className='wrap-text' style={{fontSize:'20px'}}>Ensuring everyone has a voice and feels valued as part of a larger effort for social welfare and progress.</p>
                                                                    </li>
                                                                    <li className=' justify-content-center'>
                                                                        <h6 className='px-2' style={{fontSize:'24px'}}>Collaboration </h6>
                                                                        <p className='wrap-text' style={{fontSize:'20px'}}>By working together, we strengthen community support and empower individuals to build a better future for all.</p>
                                                                    </li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="aboutDetailsThumbs pt-20">
                                                        <div className="row g-0 align-items-center justify-content-center responsive-images">
                                                                <div className="col-lg-4">
                                                                    <div className="aboutDetailsThumb">
                                                                        <img src={OurValues} alt="About Join the Movement" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <section className="about pt-40 pb-40" id='feature'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-30">
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        about foundation
                                    </span>
                                    <h2 className="sectionTitle__big">Jainam Jivika Foundation -  More Than Just a Foundation</h2>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="aboutDetails text-center">
                                    <p className="aboutDetailsText mb-20">Jainam Jivika Foundation is a social foundation dedicated to improving lives and bringing positive change to communities. Our goal is to create a lasting impact by focusing on areas that matter the most. </p>

                                    <p className="aboutDetailsText mb-20">We work on health awareness, mental well-being, cultural preservation and environmental protection. By supporting community welfare and promoting green initiatives, we aim to solve problems and make life better for everyone.</p>

                                    <p className="aboutDetailsText mb-20">Our efforts include running health initiatives, spreading mental health support and empowering communities with tools to grow and thrive. We also help preserve India’s culture and encourage sustainable living for a better future.</p>

                                    <p className="aboutDetailsText mb-20">At Jainam Jivika Foundation, we believe in working together to solve today’s challenges and build stronger, kinder communities.</p>

                                    <p className="aboutDetailsText mb-20">At Jainam Jivika Foundation, we’re not just responding to problems - we’re building solutions and creating possibilities for a better world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default WhoWeAre;
