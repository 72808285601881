import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import ContactPage from '../ContactPage/ContactPage';
import MainPage from '../HomePage2/MainPage';
import WhoWeAre from '../AboutUsPage/WhoWeAre';
import OurJourney from '../AboutUsPage/OurJourney';
import LeadershipTeam from '../AboutUsPage/LeadershipTeam';
import SevakArmy from '../Our-Initiatives/SevakArmy';
import LifeSeverArmy from '../Our-Initiatives/LifeSeverArmy';
import MyFoodChecker from '../Our-Initiatives/MyFoodChecker';
import MindUpload from '../Our-Initiatives/MindUpload';
import AkshayNetra from '../Our-Initiatives/AkshayNetra';
import YoungJains from '../Our-Initiatives/YoungJains';
import ReformArmy from '../Our-Initiatives/ReformArmy';
import SuccessStories from '../ImpactStories.js/SuccessStories';
import Testimonials from '../ImpactStories.js/Testimonials';
import TreePlantation from '../Gallery/TreePlantation';
import BloodDonation from '../Gallery/BloodDonation';
import OrphangeVisit from '../Gallery/OrphangeVisit';
import HandicapCenterVisit from '../Gallery/HandicapCenterVisit';
import SweaterDonation from '../Gallery/SweaterDonation';
import SchoolActivity from '../Gallery/SchoolActivity';
import FloodReliefMaterial from '../Gallery/FloodReliefMaterial';
import VolunteerApportunities from '../Join-us/VolunteerApportunities';
import Donate from '../Join-us/Donate';
import VideoSection from '../Videos/VideoSection';
import Achievements from '../ImpactStories.js/Achievements';
import Faq from '../FAQ/Faq';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsConditions from '../TermsConditions';
import SocialMediaLinks from '../SocialMediaLinks';
import LegalDocuments from '../LegalDocuments';
import CorporateeDonations from '../CooperateeDonation/CooperateeDonation';
import PDFs from '../PDFs/PDFs';
import PledgeMyOrgans from '../Our-Initiatives/PledgeMyOrgans';
import CookiePolicy from '../CookiePolicy';

const AllRoute = () => {

  return (
    <div className="App" style={{ overflowX: 'hidden' }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="who-we-are" element={<WhoWeAre />} />
          <Route path="our-journey" element={<OurJourney />} />
          <Route path="leadership-team" element={<LeadershipTeam />} />

          <Route path="success-stories" element={<SuccessStories />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="achievements" element={<Achievements />} />

          <Route path="legal-documents" element={<LegalDocuments />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsConditions />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="social-media-links" element={<SocialMediaLinks />} />
          <Route path="pdfs" element={<PDFs />} />


          <Route path="sevak-army" element={<SevakArmy />} />
          <Route path="my-food-checker" element={<MyFoodChecker />} />
          <Route path="mind-unload" element={<MindUpload />} />
          <Route path="akshay-netra" element={<AkshayNetra />} />
          <Route path="young-jains" element={<YoungJains />} />
          <Route path="reform-army" element={<ReformArmy />} />
          <Route path="life-saver-army" element={<LifeSeverArmy />} />
          <Route path="pledge-my-organs" element={<PledgeMyOrgans />} />


          <Route path="tree-plantation" element={<TreePlantation />} />
          <Route path="blood-donation" element={<BloodDonation />} />
          <Route path="orphanage-visit" element={<OrphangeVisit />} />
          <Route path="handicap-center-visit" element={<HandicapCenterVisit />} />
          <Route path="sweator-donation" element={<SweaterDonation />} />
          <Route path="school-activity" element={<SchoolActivity />} />
          <Route path="flood-relief-material" element={<FloodReliefMaterial />} />


          <Route path="volunteer-opportunities" element={<VolunteerApportunities />} />
          <Route path="donate" element={<Donate />} />
          <Route path="contact-us" element={<ContactPage />} />
          <Route path="videos" element={<VideoSection />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="corporate-donations" element={<CorporateeDonations />} />


          {/* <Route path="home" element={<HomePage2 />} />
          <Route path="home-2" element={<HomePage />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="home-4" element={<HomePage4 />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="donation-listing" element={<DonationListing />} />
          <Route path="donation-details/:slug" element={<DonationSinglePage />} />
          <Route path="story-details/:slug" element={<StorySinglePage />} />
          <Route path="events" element={<EventPage />} />
          <Route path="event-single/:slug" element={<EventSinglePage />} />
          <Route path="volunteers" element={<TeamPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="products" element={<ShopPage />} />
          <Route path="product-single/:slug" element={<ProductSinglePage />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="blog-details/:slug" element={<BlogDetails />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
