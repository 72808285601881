import sImg1 from '../images/utilites/dontaion-details-thumb1.jpg'
import sImg2 from '../images/utilites/dontaion-details-thumb1.jpg'
import sImg3 from '../images/utilites/dontaion-details-thumb1.jpg'
import Focused from '../images/about/focused.png'
import CommunityPartnership from '../images/about/community-partnership.png'
import Honesty from '../images/about/honesty.png'
import EasyToJoin from '../images/about/easy-to-join.png'
import EarnRewards from '../images/sewak-army/points.png'
import TrustedNGOs from '../images/sewak-army/trusted-ngos.png'
import Planning from '../images/sewak-army/planning.png'
import Communication from '../images/sewak-army/communication.png'
import Opportunities from '../images/sewak-army/problem-solving.png'
import Join from '../images/life-saver-army/follow.png'
import Community from '../images/life-saver-army/community.png'
import Available from '../images/life-saver-army/available.png'
import Challenge from '../images/life-saver-army/challenge.png'
import Personalised from '../images/food-checker/personalized.png'
import Allergy from '../images/food-checker/allergy.png'
import Ingredient from '../images/food-checker/analysis.png'
import Easy from '../images/about/easy-to-join.png'
import Family from '../images/food-checker/social-support.png'
import Stress from '../images/mind-unload/consulting.png'
import Space from '../images/mind-unload/secure.png'
import CommunityCare from '../images/mind-unload/solidarity.png'
import Workshop from '../images/mind-unload/presentation.png'
import Resources from '../images/mind-unload/teamwork.png'
import Agile from '../images/akshay/agile.png'
import Trusted from '../images/akshay/handshake.png'
import Impact from '../images/akshay/trustworthiness.png'
import Education from '../images/akshay/education.png'
import Community1 from '../images/life-saver-army/community.png'
import Technique from '../images/jains/knowledge-transfer.png'
import Resource from '../images/jains/team.png'
import Connection from '../images/jains/conference.png'
import Leader from '../images/jains/community.png'
import AmplifyVoice from '../images/reform-army/voice-recognition.png'
import CommunityEngagement from '../images/reform-army/social-support.png'
import TimeTracking from '../images/reform-army/automation.png'
import Free from '../images/reform-army/free.png'
import Plantation from '../images/volunteer-opportunities/holding-hand.png'
import BloodDonation from '../images/volunteer-opportunities/blood-donation.png'
import FloodRelief from '../images/volunteer-opportunities/flood.png'
import SchoolActivity from '../images/volunteer-opportunities/student.png'
import Transparent from '../images/donate/collective.png'
import Causes from '../images/donate/funds.png'
import Tax from '../images/donate/tax.png'
import SchoolSupplies from '../images/donate/stationary.png'
import PlantingTrees from '../images/donate/planting.png'
import CheckUp from '../images/donate/checkup.png'
import DonationDrive from '../images/donate/blood-donation.png'
import FoodDelivery from '../images/donate/fast-delivery.png'
import SevakArmy from '../images/donate/sevak-army.png'
import LifeSeverArmy from '../images/home/lfe-saver-logo.png'
import MyFoodChecker from '../images/home/food-checker-logo.png'
import MindUnload from '../images/donate/mind-unload.png'
import AkshayNetra from '../images/home/akshay-netra.png'
import ReformArmy from '../images/home/reform-army-logo.png'
import YoungJains from '../images/home/young-jain.png'
import PledgeOrgans from '../images/home/pledge-organs-logo-1.png'
import WideReach from '../images/about/wide-reach.png'


const Services = [
    {
        Id: '0',
        sImg: sImg1,
        icon: SevakArmy,
        title: 'Sevak Army',
        slug: 'together-Pledge',
        description: 'Connecting volunteers with NGOs to find tasks matching their time and skills to support social causes.',
    },
    {
        Id: '1',
        sImg: sImg2,
        icon: LifeSeverArmy,
        slug: 'Donate-for-HOmless',
        title: 'Life Saver Army',
        description: 'Linking registered donors with patients to provide life-saving blood and platelets when needed.',
    },
    {
        Id: '2',
        sImg: sImg3,
        icon: MyFoodChecker,
        title: 'My Food Checker',
        slug: 'Fundraise',
        description: 'Promoting food safety and healthy eating through scanning the food packages.',
    },
    {
        Id: '3',
        sImg: sImg3,
        icon: MindUnload,
        title: 'Mind Unload',
        slug: 'Water-&-Healthy Food',
        description: 'Offering mental health support through mindfulness sessions and stress-relief activities.',
    },
    {
        Id: '4',
        sImg: sImg3,
        icon: AkshayNetra,
        title: 'Akshay Netra',
        slug: 'Study-&-Life-Tips',
        description: 'Restoring vision through eye donations and spreading awareness to eliminate blindness.',
    },
    {
        Id: '5',
        sImg: sImg3,
        icon: YoungJains,
        title: 'Young Jains',
        slug: 'Medical-&-Aid',
        description: 'Empowering youth with Jain values like compassion, mindfulness and non-violence.',
    },
    {
        Id: '6',
        sImg: sImg3,
        icon: ReformArmy,
        title: 'Reform Army',
        slug: 'Build-Home',
        description: 'Advocating for social and environmental reforms through campaigns to build a better society.',
    },
    {
        Id: '7',
        sImg: sImg3,
        icon: PledgeOrgans,
        title: 'Pledge My Organs',
        slug: 'Build-Home',
        description: 'Supports individuals in pledging their organs, simplifying the process and raising awareness about organ donation.',
    },
    {
        Id: '8',
        sImg: sImg3,
        icon: Focused,
        title: 'Focused Projects',
        slug: 'Build-Home',
        description: 'Our initiatives solve real-world problems and significantly improve lives in a meaningful way.',
    },
    {
        Id: '9',
        sImg: sImg3,
        icon: CommunityPartnership,
        title: 'COMMUNITY COLLABORATIONS ',
        slug: 'Build-Home',
        description: 'We work closely with local communities to make sure our efforts meet their needs.',
    },
    {
        Id: '10',
        sImg: sImg3,
        icon: Honesty,
        title: 'Honesty and Transparency',
        slug: 'Build-Home',
        description: 'We act with integrity in everything we do, keeping all actions accountable.',
    },
    {
        Id: '11',
        sImg: sImg3,
        icon: EasyToJoin,
        title: 'Easy to Join',
        slug: 'Build-Home',
        description: 'We welcome everyone to get involved, whether by volunteering, donating or spreading the word.',
    },
    {
        Id: '12',
        sImg: sImg3,
        icon: WideReach,
        title: 'Wide Reach',
        slug: 'Build-Home',
        description: 'From health and education to preserving culture and protecting the environment, we take on diverse challenges.',
    },
    {
        Id: '13',
        sImg: sImg3,
        icon: EarnRewards,
        title: 'Earn Rewards',
        slug: 'Build-Home',
        description: 'Gain points, digital badges and digital certificates as you complete tasks and track your progress on the leaderboard.',
    },
    {
        Id: '14',
        sImg: sImg3,
        icon: Planning,
        title: 'Wide Range of Tasks',
        slug: 'Build-Home',
        description: 'Volunteer in diverse fields, including education, healthcare, the environment and more.',
    },
    {
        Id: '15',
        sImg: sImg3,
        icon: TrustedNGOs,
        title: 'Trusted NGOs',
        slug: 'Build-Home',
        description: 'Work with verified NGOs and know your efforts are making an impact.',
    },
    {
        Id: '16',
        sImg: sImg3,
        icon: Communication,
        title: 'Direct Communication',
        slug: 'Build-Home',
        description: 'Chat directly with NGOs for better coordination and updates.',
    },
    {
        Id: '17',
        sImg: sImg3,
        icon: Opportunities,
        title: 'Personalised Opportunities',
        slug: 'Build-Home',
        description: 'Use filters to find tasks that match your skills, time and interests.',
    },
    {
        Id: '18',
        sImg: sImg3,
        icon: Join,
        title: 'Simple to Join',
        slug: 'Build-Home',
        description: 'Whether you want to donate blood, receive blood or volunteer, signing up is quick and easy.',
    },
    {
        Id: '19',
        sImg: sImg3,
        icon: Community,
        title: 'Supportive Community',
        slug: 'Build-Home',
        description: 'Be part of a caring network where everyone works together to save lives.',
    },
    {
        Id: '20',
        sImg: sImg3,
        icon: Available,
        title: 'Always Available',
        slug: 'Build-Home',
        description: 'We ensure blood is accessible, including rare blood types, when it’s needed the most.',
    },
    {
        Id: '21',
        sImg: sImg3,
        icon: Challenge,
        title: 'Every Effort Counts',
        slug: 'Build-Home',
        description: 'Every donation and every act of support makes a difference in someone’s life.',
    },

    {
        Id: '22',
        sImg: sImg3,
        icon: Personalised,
        title: 'Personalised Profiles',
        slug: 'Build-Home',
        description: 'Create dietary profiles for yourself and your family to manage everyone’s needs easily.',
    },
    {
        Id: '23',
        sImg: sImg3,
        icon: Allergy,
        title: 'Instant Allergen Alerts',
        slug: 'Build-Home',
        description: 'Get notified about allergens or harmful ingredients with every scan.',
    },
    {
        Id: '24',
        sImg: sImg3,
        icon: Ingredient,
        title: 'Clear Ingredient Information',
        slug: 'Build-Home',
        description: 'Understand food labels effortlessly and make confident decisions.',
    },
    {
        Id: '25',
        sImg: sImg3,
        icon: Family,
        title: 'Family-Friendly Features',
        slug: 'Build-Home',
        description: 'Manage multiple profiles and ensure safety for everyone in your household.',
    },
    {
        Id: '26',
        sImg: sImg3,
        icon: Easy,
        title: 'Smart and Easy to Use',
        slug: 'Build-Home',
        description: 'Enjoy a simple, intuitive app that delivers everything you need at your fingertips.',
    },

    {
        Id: '27',
        sImg: sImg3,
        icon: Stress,
        title: 'Effortless Stress Relief',
        slug: 'Build-Home',
        description: 'Experience guided meditations, mindfulness exercises and creative outlets like art and journaling to restore balance and calm.',
    },
    {
        Id: '28',
        sImg: sImg3,
        icon: Space,
        title: 'Safe Space for Expression',
        slug: 'Build-Home',
        description: 'Share your feelings openly in a judgment-free environment. Trained listeners and supportive sessions offer clarity and emotional relief.',
    },
    {
        Id: '29',
        sImg: sImg3,
        icon: CommunityCare,
        title: 'Community of Care',
        slug: 'Build-Home',
        description: 'Connect with like-minded individuals through forums, support groups and mentorship programmes. Build strength with encouragement from those who understand.',
    },
    {
        Id: '30',
        sImg: sImg3,
        icon: Workshop,
        title: 'Expert-Led Workshops',
        slug: 'Build-Home',
        description: 'Participate in mindfulness retreats, stress management webinars and therapeutic art sessions, all led by mental health professionals.',
    },
    {
        Id: '31',
        sImg: sImg3,
        icon: Resources,
        title: 'Vent Freely, No Judgment',
        slug: 'Build-Home',
        description: 'Express yourself freely in a safe, judgment-free space. Connect with a supportive listener or peer group - no account needed.',
    },

    {
        Id: '32',
        sImg: sImg3,
        icon: Agile,
        title: 'Compassionate Process',
        slug: 'Build-Home',
        description: 'Eye donations are handled with utmost care and respect.',
    },
    {
        Id: '33',
        sImg: sImg3,
        icon: Trusted,
        title: 'Trusted Expertise',
        slug: 'Build-Home',
        description: 'Collaboration with renowned eye banks and hospitals.',
    },
    {
        Id: '34',
        sImg: sImg3,
        icon: Impact,
        title: 'Life-Changing Impact ',
        slug: 'Build-Home',
        description: 'Encouraging pledges to provide hope.',
    },
    {
        Id: '35',
        sImg: sImg3,
        icon: Education,
        title: 'Awareness and Education',
        slug: 'Build-Home',
        description: 'Campaigns and resources to inspire more pledges.',
    },
    {
        Id: '36',
        sImg: sImg3,
        icon: Community1,
        title: 'Community Engagement',
        slug: 'Build-Home',
        description: 'Building networks of donors, recipients, hospitals and volunteers.',
    },

    {
        Id: '37',
        sImg: sImg3,
        icon: Technique,
        title: 'Valuable Teachings',
        slug: 'Build-Home',
        description: 'Practical tools to apply Jain principles like non-violence and mindfulness in daily life.',
    },
    {
        Id: '38',
        sImg: sImg3,
        icon: Resource,
        title: 'Inspiring Resources',
        slug: 'Build-Home',
        description: 'Access books, videos and workshops that simplify Jain teachings for today’s world.',
    },
    {
        Id: '39',
        sImg: sImg3,
        icon: Connection,
        title: 'Building Leaders',
        slug: 'Build-Home',
        description: 'Mentorship programmes to nurture ethical and compassionate future leaders.',
    },
    {
        Id: '40',
        sImg: sImg3,
        icon: Leader,
        title: 'Community Connection',
        slug: 'Build-Home',
        description: 'Opportunities to engage with like-minded individuals and foster lifelong relationships.',
    },

    {
        Id: '41',
        sImg: sImg3,
        icon: AmplifyVoice,
        title: 'Amplify Your Voice',
        slug: 'Build-Home',
        description: 'Ensure your concerns are heard by those in power.',
    },
    // {
    //     Id: '42',
    //     sImg: sImg3,
    //     icon: Templates,
    //     title: 'Pre-Filled Templates',
    //     slug: 'Build-Home',
    //     description: 'Simplify the process with ready-to-send emails.',
    // },
    {
        Id: '43',
        sImg: sImg3,
        icon: CommunityEngagement,
        title: 'Community Engagement',
        slug: 'Build-Home',
        description: 'Join others who are passionate about similar causes.',
    },
    {
        Id: '44',
        sImg: sImg3,
        icon: TimeTracking,
        title: 'Real-Time Tracking',
        slug: 'Build-Home',
        description: 'See the progress and impact of your efforts.',
    },
    {
        Id: '45',
        sImg: sImg3,
        icon: Free,
        title: 'Free and Inclusive',
        slug: 'Build-Home',
        description: 'Accessible to anyone who wants to make a difference.',
    },


    {
        Id: '46',
        sImg: sImg3,
        icon: Plantation,
        title: 'Tree Plantation Drive',
        slug: 'Build-Home',
        date: '12/03/2024',
        description: 'Join us in greening our community.',
    },
    {
        Id: '47',
        sImg: sImg3,
        icon: BloodDonation,
        title: 'Blood Donation Camp',
        slug: 'Build-Home',
        date: '12/03/2024',
        description: ' Help save lives through your donation.',
    },
    {
        Id: '48',
        sImg: sImg3,
        icon: FloodRelief,
        title: 'Flood Relief Packing',
        slug: 'Build-Home',
        date: '12/03/2024',
        description: 'Prepare essential supplies for affected areas.',
    },
    {
        Id: '49',
        sImg: sImg3,
        icon: SchoolActivity,
        title: 'School Activities',
        slug: 'Build-Home',
        date: '12/03/2024',
        description: 'Engage with children in creative and educational programmes.',
    },


    {
        Id: '50',
        sImg: sImg3,
        icon: Transparent,
        title: 'Transparent Use of Funds',
        slug: 'Build-Home',
        description: 'Your donations drive impactful projects like disaster relief and blood drives.',
    },
    {
        Id: '51',
        sImg: sImg3,
        icon: Causes,
        title: 'Diverse Causes',
        slug: 'Build-Home',
        description: 'From empowering underprivileged communities to preserving cultural heritage, your support helps us address a wide range of critical social issues.',
    },
    {
        Id: '52',
        sImg: sImg3,
        icon: Tax,
        title: 'Tax Benefits',
        slug: 'Build-Home',
        description: 'All donations to Jainam Jivika Foundation are eligible for tax exemptions under Section 80G of the Income Tax Act.',
    },


    {
        Id: '53',
        sImg: sImg3,
        icon: SchoolSupplies,
        title: '₹500',
        slug: 'Build-Home',
        description: ' Provides school supplies for a child for a year.',
    },
    {
        Id: '54',
        sImg: sImg3,
        icon: PlantingTrees,
        title: '₹1,000',
        slug: 'Build-Home',
        description: 'Covers the cost of planting ten trees, supporting reforestation efforts.',
    },
    {
        Id: '55',
        sImg: sImg3,
        icon: CheckUp,
        title: '₹2,000',
        slug: 'Build-Home',
        description: 'Supports a health check-up camp for underprivileged families.',
    },
    {
        Id: '56',
        sImg: sImg3,
        icon: DonationDrive,
        title: '₹5,000',
        slug: 'Build-Home',
        description: ' Funds a blood donation drive or an eye donation awareness campaign.',
    },
    {
        Id: '57',
        sImg: sImg3,
        icon: FoodDelivery,
        title: '₹10,000',
        slug: 'Build-Home',
        description: 'Helps deliver food and essentials to families affected by disasters.',
    },
]

export default Services;