import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About3 from '../../components/about3/about3';
import Collaboration from '../../images/about/collaboration.png'
import Empathy from '../../images/about/empathy.png'
import Breadcumb from '../../images/about/leadership-breadcumb.jpg'


const Features2 = [
    {
        title: 'Collaboration',
        des: 'We believe teamwork brings out the best in people, tackling even the toughest challenges.',
        icon: Collaboration,
        width: '80%',
        auth: 'Co-Founder',
        duration: 1000,
    },
    {
        title: 'Empathy',
        des: 'Understanding the struggles of others is at the core of every initiative we undertake.',
        icon: Empathy,
        width: '94%',
        auth: 'Co-Founder',
        duration: 1200,
    },
    // {
    //     title: 'Our Future Goals',
    //     des: 'By 2025, we want to create 2 million volunteering hours, empowering people to contribute to society.',
    //     icon: FutureGoals,
    //     width: '70%',
    //     auth: 'Co-Founder.',
    //     duration: 1400,
    // },
]

const LeadershipTeam = (props) => {

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Leadership Team'} pagesub={'About Us'} bg={Breadcumb} />

                {/* <div className='mt-5 pt-4'>
                    <About2 />
                </div> */}

                <section className="about pt-40 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-30">
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Leadership Team
                                    </span>
                                    <h2 className="sectionTitle__big">Our Leadership</h2>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="aboutDetails text-center">
                                    <p className="aboutDetailsText mb-20">The leadership team at Jainam Jivika Foundation is the heart of our mission, working with passion, dedication and a clear vision for a better future. They play a vital role in guiding our initiatives, ensuring that every action we take creates meaningful change. Whether it’s leading tree plantation drives to protect the environment organising blood donation camps to save lives or coordinating disaster relief efforts during emergencies, our leaders turn every effort into an opportunity to give back to society.</p>

                                    <p className="aboutDetailsText mb-20">Their work doesn’t stop at large-scale initiatives. They lead by example, transforming everyday moments into impactful actions. From turning office celebrations, like birthdays and anniversaries, into donation drives to personally visiting orphanages and differently-abled care centers to bring joy and support, their actions inspire kindness and community spirit in everyone around them.</p>

                                    <p className="aboutDetailsText mb-20">What sets our leadership apart is their ability to take simple ideas and turn them into powerful initiatives that touch lives in meaningful ways. They encourage teamwork, foster collaboration and ensure that everyone, whether volunteers, donors or beneficiaries, should feel valued and included.</p>

                                    <p className="aboutDetailsText mb-20">Together, our leadership team is working to build a world where compassion fuels progress, opportunities are accessible to everyone and every individual has the chance to thrive. They inspire all of us to go beyond our limits and be part of creating a brighter and more caring future.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='gray-bg'>
                    <About3 ctaSection={false} subtitle="Leadership Philosophy" title="Our Leader’s Speak" Features2={Features2} />
                </div>

                {/* <Testimonial3 /> */}
                {/* <StoriesSection /> */}

                {/* <AboutTab /> */}

                {/* <div className='pt-50'>
                    <About4 />
                </div> */}

                {/* <div className='mt-5 pt-4'>
                    <About2 />
                </div> */}


                {/* <About3 /> */}

                {/* <div className='pt-120'>
                    <DonationListSection />
                </div> */}

                {/* <FunFactSection /> */}


                {/* <div className='pt-100 pb-100'>
                    <CtaSection />
                </div> */}

                {/* <TeamSection />
                <div className=''>
                    <BlogSection2 />
                </div> */}

                {/* <div className='pt-80'>
                    <TeamSection />
                </div> */}
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default LeadershipTeam;
