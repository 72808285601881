import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Breadcumb from '../../images/orphanage-visit/breadcumb-1.jpg'
import o2 from '../../images/orphanage-visit/2.jpg'
import o3 from '../../images/orphanage-visit/3.jpg'
import o4 from '../../images/orphanage-visit/4.jpg'
import o5 from '../../images/orphanage-visit/5.jpg'
import o7 from '../../images/orphanage-visit/7.jpg'
import o8 from '../../images/orphanage-visit/8.jpg'
import o9 from '../../images/orphanage-visit/9.jpg'
import o10 from '../../images/orphanage-visit/10.jpg'
import o1 from '../../images/orphanage-visit/orphanage-1.png'

const OrphangeVisit = (props) => {

    
   

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Orphanage Vsit'} pagesub={'Gallery'} bg={Breadcumb} />

              
                <section className="blog pt-60">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <div className="sectionTitle mb-40" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>Orphanage Visit
                                    </span>
                                    <h2 className="sectionTitle__big text-center">Orphanage Visit</h2>
                                    <p className='text-center' style={{ fontSize: '24px' }}>Heartwarming images from our visits, spreading love and joy to children.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        {/* <Link to="#" */}
                                        {/* onClick={(e) => e.preventDefault()} className="blogBlock__figure__link"> */}
                                        <img src={o1} alt="Stronger Together" className="blogBlock__figure__image" />
                                        {/* </Link> */}
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o2} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o3} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o4} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o5} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o6} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o7} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o8} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o9} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={o10} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default OrphangeVisit;
