import React from "react";
import { Link } from "react-router-dom";
import shape1 from "../../images/shapes/pagebreadcumbShapeTop.svg";
import shape2 from "../../images/shapes/pagebreadcumbShapeBottom.svg";

const PageTitle = (props) => {
  const isFaqPage = props.pageTitle === "FAQs";
  return (
    <section
      className="pageBreadcumb pageBreadcumb--style1 position-relative"
      // style={{ backgroundImage: `url(${props.bg})` }}
      style={{ backgroundColor:'#231D1D' }}
    >
      
      <div className="sectionShape sectionShape--top">
        <img src={shape1} alt="Join the Movement" />
      </div>
      <div className="sectionShape sectionShape--bottom">
        <img src={shape2} alt="Join the Movement" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="pageTitle text-center">
              <h2
                className={`pageTitle__heading text-white mb-25 ${
                  isFaqPage ? "" : "text-uppercase"
                }`}
              >
                {props.pageTitle}
              </h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{
                      textTransform:
                        props.pageTitle === "FAQs" ? "none" : "uppercase",
                    }}
                  >
                    {props.pagesub}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
