import React from "react";
import { Link } from "react-router-dom";
import about1 from "../../images/man/about-man-h3.jpg";
import { Slide } from "react-awesome-reveal";

const About3 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section
      className={`about ${props.bg}-bg about--style3`}
      style={{ paddingTop: "50px" }}
    >
      {props.ctaSection && (
        <>
          <div className="aboutThumb3">
            <img src={about1} alt="Join the Movement" />
          </div>
          <div className="container">
            <div className="row align-items-end justify-content-between">
              <div className="col-lg-6 mb-30">
                <div className="aboutContent aboutContent--style2">
                  <div className="sectionTitle mb-20">
                    <span className="sectionTitle__small">
                      <i className="fa-solid fa-heart btn__icon"></i>
                      about foundation
                    </span>
                    <h2 className="sectionTitle__big">
                      what have we done with your help
                    </h2>
                  </div>
                  <p
                    className="aboutContent__text"
                    style={{ textAlign: "justify" }}
                  >
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage Lorem of Lorem Ipsum, you need to be sure there
                    isn't anything embarrassing hidden in the middle There are
                    many variations of passages
                  </p>
                  <span className="aboutContent__quote">
                    join our Action and everyone can help
                  </span>
                  <div className="aboutContent__buttons">
                    <Link
                      to="/donate"
                      onClick={ClickHandler}
                      className="btn btn--styleOne btn--secondary it-btn"
                    >
                      <span className="btn__text">donate now</span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                    <Link
                      onClick={ClickHandler}
                      to="/volunteer-opportunities"
                      className="btn btn--styleOne btn--primary it-btn"
                    >
                      <span className="btn__text">became a volenteer</span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="aboutThumb aboutThumb--style3">
                  <div className="aboutThumb__text">
                    <span className="aboutThumb__text__title">..Since..</span>
                    <span className="aboutThumb__text__year">1998</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="services services--style3 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 maindiv">
              <div className="sectionTitle sectionntitle  mb-30">
                <span className="sectionTitle__small sectionSubtitle ">
                  <i className="fa-solid fa-heart btn__icon"></i>
                  {props.subtitle}
                </span>
                <h2 className="sectionTitle__big">{props.title}</h2>
                {props.para && (
                  <p style={{ fontSize: "20px" }}>
                    Your donation supports impactful programmes, including
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={`row gx-35 pt-35 justify-content-center gy-45 align-items-stretch flex-wrap px-2`}>
            {props.Features2.map((features, fitem) => (
              <div className={` d-flex flex-wrap mb-3 ${props.boxes === 3 ? 'col-xl-4 col-lg-6 col-sm-11 col-12' : 'col-xl-3 col-lg-6 col-sm-11 col-12'}`} key={fitem}>
                <Slide
                  direction="up"
                  triggerOnce={"false"}
                  duration={features.duration}
                  style={{ width: '100%' }}
                >
                  <div className="keyFeatureBlock keyFeatureBlock--style4 mb-30 w-100 d-flex flex-column h-100">
                    <div className="keyFeatureBlock__left">
                      <span className="keyFeatureBlock__icon" style={{ height: props.iconSize ? props.iconSize : '', width: props.iconSize ? props.iconSize : '' }}>
                        <img
                          src={features.icon}
                          style={{ height: '50px' }}
                          // style={{ height: props.isHeight ? '50px' : 'auto', width:props.width ? props.width : 'auto' }}
                          alt="Hope in Action"
                        />
                      </span>
                    </div>
                    <div className="keyFeatureBlock__content flex-grow-1">
                      <h3 className="keyFeatureBlock__heading">
                        <div className="keyFeatureBlock__heading__link">
                          {features.title}
                        </div>
                      </h3>
                      <p
                        className="keyFeatureBlock__text"
                        style={{ textAlign: "justify" }}
                      >
                        {features.des}
                      </p>
                    </div>
                    {features.auth && (
                      <div className="keyFeatureBlock__content mt-2 d-flex w-100 justify-content-end">
                        <p
                          className="keyFeatureBlock__text"
                          style={{
                            width: "100%",
                            textAlign: "right",
                            fontWeight: "800",
                          }}
                        >
                          - {features.auth}
                        </p>
                      </div>
                    )}
                  </div>
                </Slide>
              </div>
            ))}
          </div>

          {props.ctabutton && (
            <>
              <div className="aboutContent__buttons d-flex justify-content-center mt-4">
                <Link
                  to="/contact-us"
                  onClick={ClickHandler}
                  className="btn btn--styleOne btn--secondary it-btn"
                >
                  <span className="btn__text">{props.button}</span>
                  <i className="fa-solid fa-heart btn__icon"></i>
                  <span className="it-btn__inner">
                    <span className="it-btn__blobs">
                      <span className="it-btn__blob"></span>
                      <span className="it-btn__blob"></span>
                      <span className="it-btn__blob"></span>
                      <span className="it-btn__blob"></span>
                    </span>
                  </span>
                  <svg
                    className="it-btn__animation"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                  >
                    <defs>
                      <filter>
                        <feGaussianBlur
                          in="SourceGraphic"
                          result="blur"
                          stdDeviation="10"
                        ></feGaussianBlur>
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                          result="goo"
                        ></feColorMatrix>
                        <feBlend
                          in2="goo"
                          in="SourceGraphic"
                          result="mix"
                        ></feBlend>
                      </filter>
                    </defs>
                  </svg>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default About3;
