import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import TreePlantation1 from "../../images/tree-plantation/tree-plantation-1.png";
import TreePlantation2 from "../../images/tree-plantation/tree-plantation-2.png";
import TreePlantation3 from "../../images/tree-plantation/tree-plantation-3.png";
import TreePlantation4 from "../../images/tree-plantation/tree-plantation-4.png";
import TreePlantation5 from "../../images/tree-plantation/tree-plantation-5.png";
import TreePlantation6 from "../../images/tree-plantation/tree-plantation-6.png";
import TreePlantation7 from "../../images/tree-plantation/tree-plantation-7.png";
import TreePlantation8 from "../../images/tree-plantation/tree-plantation-8.png";
import TreePlantation9 from "../../images/tree-plantation/tree-plantation-9.png";
import TreePlantation10 from "../../images/tree-plantation/tree-plantation-10.png";
import TreePlantation11 from "../../images/tree-plantation/tree-plantation-11.png";
import TreePlantation12 from "../../images/tree-plantation/tree-plantation-12.png";
import TreePlantation13 from "../../images/tree-plantation/tree-plantation-13.png";
import TreePlantation14 from "../../images/tree-plantation/tree-plantation-14.png";
import TreePlantation15 from "../../images/tree-plantation/tree-plantation-15.png";
import Breadcumb from "../../images/tree-plantation/breadcumb.jpg";

const TreePlantation = (props) => {
  return (
    <Fragment>
      <Header hclass={"header--styleFour"} />
      <main className="page_content">
        <PageTitle
          pageTitle={"Tree Plantation"}
          pagesub={"Gallery"}
          bg={Breadcumb}
        />

        {/* <div className='mt-5 pt-4 pb-100'>
                    <About2 />
                </div> */}

        <section className="blog pt-60">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-12">
                <div className="sectionTitle mb-40">
                  <span className="sectionTitle__small justify-content-center">
                    <i className="fa-solid fa-heart btn__icon"></i>Tree
                    Plantation
                  </span>
                  <h2 className="sectionTitle__big text-center">Tree Plantation</h2>
                  <p className="text-center" style={{ fontSize: "24px" }}>
                    Captured moments of our efforts to make the world greener
                    and more sustainable.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    {/* <Link to="#" */}
                    {/* onClick={(e) => e.preventDefault()} className="blogBlock__figure__link"> */}
                    <img
                      src={TreePlantation1}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                    {/* </Link> */}
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation2}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation3}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation4}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation5}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation6}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation7}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation8}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation9}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation10}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation11}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation12}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation13}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation14}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blogBlock blogBlock--style3 mb-60">
                  <figure className="blogBlock__figure">
                    <img
                      src={TreePlantation15}
                      alt="Stronger Together"
                      className="blogBlock__figure__image"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default TreePlantation;
