import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Breadcumb from '../../images/docs/faq-banner-3.png'
import Footer from '../../components/footer/Footer';
import { Helmet } from "react-helmet";

const accordionData1 = [
    {
        title: "What is Jainam Jivika Foundation?",
        content: (
            <>
                Jainam Jivika Foundation is a social foundation dedicated to uplifting communities through various initiatives like health, education, environment and social change.
            </>
        )
    },
    {
        title: "What are the main focus areas of Jainam Jivika Foundation?",
        content: (
            <>
                Our focus areas include health, education, mental well-being, environmental protection, cultural preservation and community service.
            </>
        )
    },
    {
        title: "When was Jainam Jivika Foundation founded?",
        content: (
            <>
                Jainam Jivika Foundation was founded in 2020 with the vision to empower communities and create lasting impact.
            </>
        )
    },
    {
        title: "Is Jainam Jivika Foundation a registered organisation?",
        content: (
            <>
                Yes, Jainam Jivika Foundation is a registered non-profit organisation.
            </>
        )
    },
    {
        title: "Where is Jainam Jivika Foundation headquartered?",
        content: (
            <>
                Our headquarters are located in Pune, Maharashtra, India.
            </>
        )
    },
];

const accordionData9 = [
    {
        title: "How does Jainam Jivika Foundation measure its impact?",
        content: (
            <>
                We track the number of beneficiaries, volunteers and projects completed through detailed reports.
            </>
        )
    },
    {
        title: "Can I see how my donation or contribution is being used?",
        content: (
            <>
                Yes, we maintain transparency through reports shared on our website and social media.
            </>
        )
    },
];

const accordionData10 = [
    {
        title: "Can I intern with Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we offer internships for students and professionals passionate about social work.
            </>
        )
    },
    {
        title: "How can I contact Jainam Jivika Foundation?",
        content: (
            <>
                Visit our “Contact Us” page or email us at  <a
                    href="mailto:info@JJFIndia.com" target="_blank" rel="noreferrer" className="text-blue-500">info@JJFIndia.com</a>.
            </>
        )
    },
    {
        title: "Does Jainam Jivika Foundation have international chapters?",
        content: (
            <>
                Currently, our focus is on India, but we welcome global volunteers and donors.
            </>
        )
    },
    {
        title: "Can I suggest a project or initiative to Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we are open to ideas. Reach out to us via email.
            </>
        )
    },
    {
        title: "Is Jainam Jivika Foundation affiliated with any religious organisations?",
        content: (
            <>
                While we are inspired by Jain principles, Jainam Jivika Foundation operates as a secular foundation supporting diverse causes.
            </>
        )
    },
    {
        title: "Can I work full-time with Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we occasionally have job openings. Check our website for updates.
            </>
        )
    },
    {
        title: "Does Jainam Jivika Foundation provide certificates to volunteers?",
        content: (
            <>
                Yes, volunteers receive certificates for their contributions.
            </>
        )
    },
    {
        title: "What is the best way to support Jainam Jivika Foundation?",
        content: (
            <>
                You can donate, volunteer or spread the word about our initiatives.
            </>
        )
    },
    {
        title: "Can schools and colleges collaborate with Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we encourage institutions to involve their students in community service through our programmes.
            </>
        )
    },
    {
        title: "How can influencers support Jainam Jivika Foundation?",
        content: (
            <>
                Influencers can promote our campaigns and initiatives on social media to increase awareness and participation.
            </>
        )
    },
];

const accordionData6 = [
    {
        title: "How can organisations collaborate with Jainam Jivika Foundation?",
        content: (
            <>
                Organisations can partner with us for CSR initiatives, employee volunteering programmes and sponsorships.
            </>
        )
    },
    {
        title: "Do you offer CSR opportunities for companies?",
        content: (
            <>
                Yes, we customise CSR programmes to align with the company's values and community impact goals.
            </>
        )
    },
    {
        title: "Can companies donate to Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we welcome corporate donations and partnerships.
            </>
        )
    },

];

const accordionData5 = [
    {
        title: "How can I donate to Jainam Jivika Foundation?",
        content: (
            <>
                You can donate through our website under the "Join Us" section or via bank transfer and UPI options provided.
            </>
        )
    },
    {
        title: "Is my donation tax-deductible?",
        content: (
            <>
                Yes, donations to Jainam Jivika Foundation are eligible for tax deductions under Section 80G of the Income Tax Act.
            </>
        )
    },
    {
        title: "How is my donation used?",
        content: (
            <>
                Donations are used to fund our initiatives, including healthcare, education, disaster relief and community service.
            </>
        )
    },
    {
        title: "Can I make a one-time or recurring donation?",
        content: (
            <>
                Yes, both options are available. You can choose what works best for you.
            </>
        )
    },
    {
        title: "Will I receive a receipt for my donation?",
        content: (
            <>
                Yes, a receipt will be emailed to you upon successful donation.
            </>
        )
    },
    {
        title: "Can I donate items like clothes or food instead of money?",
        content: (
            <>
                Yes, we accept in-kind donations. Please contact us for specific requirements.
            </>
        )
    },
    {
        title: "How can I sponsor a specific initiative or project?",
        content: (
            <>
                You can write to us at  <a
                    href="mailto:info@JJFIndia.com" target="_blank" rel="noreferrer" className="text-blue-500">info@JJFIndia.com</a> for sponsorship opportunities.
            </>
        )
    },
    {
        title: "Can I donate anonymously?",
        content: (
            <>
                Yes, we respect your privacy and allow anonymous donations.
            </>
        )
    },
];

const accordionData7 = [
    {
        title: "What types of events does Jainam Jivika Foundation organise?",
        content: (
            <>
                We organise events like blood donation drives, tree plantations, workshops and disaster relief campaigns.
            </>
        )
    },
    {
        title: "Can I participate in Jainam Jivika Foundation events as a volunteer?",
        content: (
            <>
                Yes, volunteers are integral to the success of our events.
            </>
        )
    },
    {
        title: "How can I stay updated on upcoming events?",
        content: (
            <>
                Follow us on social media or subscribe to our newsletter for updates on events and campaigns.
            </>
        )
    },
    {
        title: "Does Jainam Jivika Foundation organise awareness campaigns?",
        content: (
            <>
                Yes, we run campaigns on health, education, mental well-being and environmental protection.
            </>
        )
    },

];

const accordionData8 = [
    {
        title: "How can NGOs collaborate with Jainam Jivika Foundation?",
        content: (
            <>
                NGOs can register with initiatives like Sevak Army to find volunteers and resources.
            </>
        )
    },
    {
        title: "Do NGOs need to pay to use Jainam Jivika Foundation’s platform?",
        content: (
            <>
                No, our platforms are free for NGOs to use.
            </>
        )
    },
    {
        title: "Can small NGOs partner with Jainam Jivika Foundation?",
        content: (
            <>
                Yes, we work with NGOs of all sizes to drive meaningful social change.
            </>
        )
    },
];

const accordionData3 = [
    {
        title: "How can I become a volunteer with Jainam Jivika Foundation?",
        content: (
            <>
                You can register on our website under the "Join Us" section to become a volunteer.
            </>
        )
    },
    {
        title: "Do I need specific skills to volunteer?",
        content: (
            <>
                No, we welcome volunteers of all skill levels. We match opportunities based on your interests and expertise.
            </>
        )
    },
    {
        title: "Are there virtual volunteering opportunities available?",
        content: (
            <>
                Yes, certain tasks like content creation, campaign management and fundraising can be done virtually.
            </>
        )
    },
    {
        title: "Can students volunteer with Jainam Jivika Foundation?",
        content: (
            <>
                Absolutely! We encourage students to participate in community-building activities.
            </>
        )
    },
    {
        title: "How do I track my volunteer hours?",
        content: (
            <>
                Volunteers can track their hours through our platforms like Sevak Army.
            </>
        )
    },
    {
        title: "Is there an age limit to volunteering?",
        content: (
            <>
                Volunteers above the age of 16 can register with Jainam Jivika Foundation.
            </>
        )
    },
    {
        title: "What kind of tasks can I do as a volunteer?",
        content: (
            <>
                Tasks range from organising events, teaching, planting trees and raising awareness to supporting campaigns and administrative work.
            </>
        )
    },
];

const accordionData2 = [
    {
        title: "What is Sevak Army?",
        content: (
            <>
                Sevak Army is our initiative to connect volunteers with meaningful opportunities to serve NGOs and communities.
            </>
        )
    },
    {
        title: "What is Life Saver Army?",
        content: (
            <>
                Life Saver Army is a platform connecting blood and platelet donors with patients in need during emergencies.
            </>
        )
    },
    {
        title: "What is My Food Checker?",
        content: (
            <>
                My Food Checker is an initiative to promote informed food choices by helping users identify allergens and unhealthy ingredients.
            </>
        )
    },
    {
        title: "What is Mind Unload?",
        content: (
            <>
                Mind Unload focuses on mental well-being through stress-relief activities, mindfulness sessions and supportive communities.
            </>
        )
    },
    {
        title: "What is Akshay Netra?",
        content: (
            <>
                Akshay Netra is our initiative to encourage people to pledge their eyes and raise awareness on preventable blindness.
            </>
        )
    },
    {
        title: "What is Young Jains?",
        content: (
            <>
                Young Jains empowers the younger generation to embrace Jain values through workshops, videos and cultural events.
            </>
        )
    },
    {
        title: "What is Reform Army?",
        content: (
            <>
                Reform Army is a platform for citizens to raise petitions on social, environmental and policy-related issues.
            </>
        )
    },
    {
        title: "Are there any flagship programmes directly managed by Jainam Jivika Foundation?",
        content: (
            <>
                Yes, Jainam Jivika Foundation Core Programmes focus on education, healthcare and environmental sustainability.
            </>
        )
    },
];

const Faq = (props) => {

    const [activeAccordion1, setActiveAccordion1] = useState(null);
    const [activeAccordion2, setActiveAccordion2] = useState(null);
    const [activeAccordion3, setActiveAccordion3] = useState(null);
    const [activeAccordion4, setActiveAccordion4] = useState(null);
    const [activeAccordion5, setActiveAccordion5] = useState(null);
    const [activeAccordion6, setActiveAccordion6] = useState(null);
    const [activeAccordion7, setActiveAccordion7] = useState(null);
    const [activeAccordion8, setActiveAccordion8] = useState(null);
    const [activeAccordion9, setActiveAccordion9] = useState(null);


    const toggleAccordion1 = (index) => {
        setActiveAccordion1(activeAccordion1 === index ? null : index);
    };

    const toggleAccordion2 = (index) => {
        setActiveAccordion2(activeAccordion2 === index ? null : index);
    };

    const toggleAccordion3 = (index) => {
        setActiveAccordion3(activeAccordion3 === index ? null : index);
    };

    const toggleAccordion4 = (index) => {
        setActiveAccordion4(activeAccordion4 === index ? null : index);
    };

    const toggleAccordion5 = (index) => {
        setActiveAccordion5(activeAccordion5 === index ? null : index);
    };

    const toggleAccordion6 = (index) => {
        setActiveAccordion6(activeAccordion6 === index ? null : index);
    };

    const toggleAccordion7 = (index) => {
        setActiveAccordion7(activeAccordion7 === index ? null : index);
    };

    const toggleAccordion8 = (index) => {
        setActiveAccordion8(activeAccordion8 === index ? null : index);
    };

    const toggleAccordion9 = (index) => {
        setActiveAccordion9(activeAccordion9 === index ? null : index);
    };


    return (
        <Fragment>
            <Helmet>
                <title>JJFIndia | FAQs</title>
                <meta
                    name="description"
                    content="Welcome to Jainam Jivika Foundation's home page."
                />
            </Helmet>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={"FAQs"} pagesub={"FAQs"} id="faqs" style={{ textTransform: "none" }} bg={Breadcumb} />

                {/* <div className='mt-5 pt-4 pb-100'>
                    <About2 />
                </div> */}

                <section className="about pt-60">
                    <div className="container">
                        <div className="w-100 row align-items-center justify-content-center">
                            <div className="col-lg-10">
                                <div className="aboutContent aboutContent--style2">
                                    <div className="sectionTitle d-flex flex-column justify-content-center text-center">
                                        <span className="sectionTitle__small w-100 justify-content-center" style={{ textTransform: "none" }}>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            FAQs
                                        </span>
                                        <h2 className="sectionTitle__big text-center" style={{textTransform:'none'}}>GENERAL FAQs FOR JAINAM JIVIKA FOUNDATION</h2>
                                    </div>
                                    <p className="aboutContent__text text-center" style={{ fontSize: '20px' }}>
                                        Here’s a detailed list of frequently asked questions (FAQs) to help you understand Jainam Jivika Foundation, its initiatives and how you can get involved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle ' id='feature'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>About Jainam Jivika Foundation</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample">
                                                        {accordionData1.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion1 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion1(index)}
                                                                        aria-expanded={activeAccordion1 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion1 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Initiative </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample1">
                                                        {accordionData2.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion2 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion2(index)}
                                                                        aria-expanded={activeAccordion2 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion2 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample1"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Volunteer Opportunities </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample2">
                                                        {accordionData3.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion3 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion3(index)}
                                                                        aria-expanded={activeAccordion3 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion3 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample2"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Donations</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample3">
                                                        {accordionData5.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion4 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion4(index)}
                                                                        aria-expanded={activeAccordion4 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion4 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample3"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Corporate Partnerships </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample4">
                                                        {accordionData6.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion5 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion5(index)}
                                                                        aria-expanded={activeAccordion5 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion5 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample4"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Events and Campaigns</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample5">
                                                        {accordionData7.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion6 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion6(index)}
                                                                        aria-expanded={activeAccordion6 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion6 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample5"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Partnerships with NGOs </h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample6">
                                                        {accordionData8.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion7 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion7(index)}
                                                                        aria-expanded={activeAccordion7 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion7 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample6"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Impact</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample7">
                                                        {accordionData9.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion8 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion8(index)}
                                                                        aria-expanded={activeAccordion8 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion8 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample7"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="ro">
                            <div className="col-lg-12 col-md-12">
                                <div className="wpo-service-single-wrap">
                                    <div className="wpo-benefits-section">
                                        <div className='sectionTitle'>
                                            <h2 className="text-center sectionTitle__big" style={{ textTransform: 'capitalize' }}>Miscellaneous</h2>
                                        </div>
                                        <div className="row pb-40">
                                            <div className="col-lg-12 col-12">
                                                <div className="wpo-benefits-item">
                                                    <div className="accordion" id="accordionExample8">
                                                        {accordionData10.map((item, index) => (
                                                            <div className="accordion-item" key={index}>
                                                                <h3 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className={`accordion-button ${activeAccordion9 === index ? "" : "collapsed"}`}
                                                                        type="button"
                                                                        style={{ fontSize: '20px' }}
                                                                        onClick={() => toggleAccordion9(index)}
                                                                        aria-expanded={activeAccordion9 === index ? "true" : "false"}
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        {item.title}
                                                                    </button>
                                                                </h3>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className={`accordion-collapse collapse ${activeAccordion9 === index ? "show" : ""}`}
                                                                    aria-labelledby={`heading${index}`}
                                                                    data-bs-parent="#accordionExample8"
                                                                >
                                                                    <div className="accordion-body" style={{ fontSize: '18px' }}>
                                                                        <p>{item.content}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Faq;
