import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Home 1',
        //         link: '/home'
        //     },
        //     {
        //         id: 12,
        //         title: 'Home 2',
        //         link: '/home-2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Home 3',
        //         link: '/home-3'
        //     },
        //     {
        //         id: 14,
        //         title: 'Home 4',
        //         link: '/home-4'
        //     }
        // ]
    },
    {
        id: 2,
        title: 'About Us',
        link: '#',
        submenu: [
            {
                id: 11,
                title: 'Who We Are?',
                link: '/who-we-are'
            },
            {
                id: 12,
                title: 'Our Journey',
                link: '/our-journey'
            },
            {
                id: 13,
                title: 'Leadership Team',
                link: '/leadership-team'
            },
        ]
    },
    {
        id: 3,
        title: 'Our Initiatives',
        link: '#',
        submenu: [
            {
                id: 31,
                title: 'SevakArmy.com',
                link: '/sevak-army'
            },
            {
                id: 32,
                title: 'LifeSaverArmy.com',
                link: '/life-saver-army'
            },
            {
                id: 33,
                title: 'MyFoodChecker.com',
                link: '/my-food-checker'
            },
            {
                id: 34,
                title: 'MindUnload.com',
                link: '/mind-unload'
            },
            {
                id: 35,
                title: 'AkshayNetra.com',
                link: '/akshay-netra'
            },
            {
                id: 36,
                title: 'YoungJains.com',
                link: '/young-jains'
            },
            {
                id: 37,
                title: 'ReformArmy.com',
                link: '/reform-army'
            },
            {
                id: 37,
                title: 'PledgeMyOrgans.com',
                link: '/pledge-my-organs'
            },
        ]
    },
    {
        id: 4,
        title: 'Impact & Stories',
        link: '#',
        submenu: [
            {
                id: 41,
                title: 'Success Stories',
                link: '/success-stories'
            },
            {
                id: 42,
                title: 'Testimonials',
                link: '/testimonials'
            },
            {
                id: 43,
                title: 'Achievements',
                link: '/achievements'
            },
        ]
    },
    {
        id: 5,
        title: 'Gallery',
        link: '#',
        submenu: [
            {
                id: 51,
                title: 'Tree Plantation',
                link: '/tree-plantation'
            },
            {
                id: 52,
                title: 'Blood Donation',
                link: '/blood-donation'
            },
            {
                id: 51,
                title: 'Orphanage Visit',
                link: '/orphanage-visit'
            },
            {
                id: 52,
                title: 'Handicap Center Visit',
                link: '/handicap-center-visit'
            },
            {
                id: 53,
                title: 'Sweator Donation',
                link: '/sweator-donation'
            },
            {
                id: 54,
                title: 'School Activity',
                link: '/school-activity'
            },
            {
                id: 55,
                title: 'Flood Relief Material',
                link: '/flood-relief-material'
            },
        ]
    },
    // {
    //     id: 88,
    //     title: 'Videos',
    //     link: '/videos',
    // },
    {
        id: 89,
        title: 'Join Us',
        link: '#',
        submenu: [
            {
                id: 51,
                title: 'Volunteer Opportunities',
                link: '/volunteer-opportunities'
            },
            {
                id: 52,
                title: 'Donate',
                link: '/donate'
            },
        ]
    },
    {
        id: 90,
        title: 'Contact Us',
        link: '/contact-us',
    },


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        {item.submenu ?
                            <Fragment>
                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                    <i className={item.id === openId ? 'ti-minus' : 'ti-plus'}></i>
                                </p>
                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                    <List className="subMenu">
                                        <Fragment>
                                            {item.submenu.map((submenu, i) => {
                                                return (
                                                    <ListItem key={i}>
                                                        <Link onClick={ClickHandler} className="active"
                                                            to={submenu.link}>{submenu.title}</Link>
                                                    </ListItem>
                                                )
                                            })}
                                        </Fragment>
                                    </List>
                                </Collapse>
                            </Fragment>
                            : <Link className="active"
                                to={item.link}>{item.title}</Link>
                        }
                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;