import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Breadcumb from '../../images/about/our-journey-breadcumb.jpg';


const OurJourney = (props) => {

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Our Journey'} pagesub={'About Us'} bg={Breadcumb} />

                <section className="about pt-40 pb-40">
                    <div className="container">
                        <div className="row ps-3 ps-lg-0">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-30">
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Our Journey
                                    </span>
                                    <h2 className="sectionTitle__big">A Journey of Commitment, Compassion and Dedication</h2>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="aboutDetails text-center">
                                    <p className="aboutDetailsText mb-20">Since we started, Jainam Jivika Foundation has been committed to bringing positive changes to communities across India. Our journey has included visiting orphanages, supporting people with disabilities, running educational programmes and providing disaster relief. Every step we take is aimed at building stronger, more caring and connected communities.
                                    </p>
                                    <p className="aboutDetailsText mb-20">
                                        Our commitment extends to social welfare, education, healthcare and environmental sustainability, ensuring a lasting impact on the lives we touch. None of this would be possible without the unwavering support of our volunteers, donors and leadership team, who work together with a shared vision of making a real difference.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='gray-bg pb-0' id='feature'>
                    <ServiceSection subtitle="Why Choose Us?" title="Why Choose Jainam Jivika Foundation?" center={true} className="responsive-our-journey-div" para={<>At Jainam Jivika Foundation, we don’t just aim to help; we take action to create real and lasting change. Here’s what makes us special: </>} startIndex="8" endIndex="12" endPara={<> By choosing Jainam Jivika Foundation, you become part of a foundation that brings hope and opportunities to those who need them the most. Together, we can create a better and brighter future for everyone. </>} />
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default OurJourney;
