import React, { Fragment} from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import Breadcumb from '../../images/school-activity/breadcumb.jpg'
import q1 from '../../images/school-activity/1.jpg'
import q2 from '../../images/school-activity/2.jpg'
import q3 from '../../images/school-activity/3.jpg'
import q4 from '../../images/school-activity/4.jpg'
import q5 from '../../images/school-activity/5.jpg'
import q6 from '../../images/school-activity/6.jpg'
import q7 from '../../images/school-activity/7.jpg'
import q9 from '../../images/school-activity/9.jpg'
import q0 from '../../images/school-activity/10.jpg'

const SchoolActivity = (props) => {

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'School Activity'} pagesub={'Gallery'} bg={Breadcumb} />

          
                <section className="blog pt-60">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <div className="sectionTitle mb-40" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>School Activity
                                    </span>
                                    <h2 className="sectionTitle__big text-center">School Activity</h2>
                                    <p className='text-center' style={{ fontSize: '24px' }}>Glimpses of our engaging activities to inspire and educate young minds.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-40">
                                    <figure className="blogBlock__figure">
                                        {/* <Link to="#" */}
                                        {/* onClick={(e) => e.preventDefault()} className="blogBlock__figure__link"> */}
                                        <img src={q1} alt="Stronger Together" className="blogBlock__figure__image" />
                                        {/* </Link> */}
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q2} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q3} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q4} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q5} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q6} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q7} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q8} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q9} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="blogBlock blogBlock--style3 mb-60">
                                    <figure className="blogBlock__figure">
                                        <img src={q0} alt="Stronger Together" className="blogBlock__figure__image" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default SchoolActivity;
