import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import Breadcumb from '../../images/videos/breadcumb-1.png'

const Donation = [
    {
        Id: '1',
        videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
        title: 'Gift an Education… Make a Life Better!',
        thumb: '#Love',
        category: 'education',
    },
    {
        Id: '2',
        videoUrl: 'https://www.youtube.com/embed/3JZ_D3ELwOQ',
        title: 'Support Medical Relief',
        thumb: '#Care',
        category: 'medical',
    },
    {
        Id: '3',
        videoUrl: 'https://www.youtube.com/embed/3JZ_D3ELwOQ',
        title: 'Support Medical Relief',
        thumb: '#Care',
        category: 'medical',
    },
    {
        Id: '4',
        videoUrl: 'https://www.youtube.com/embed/3JZ_D3ELwOQ',
        title: 'Support Medical Relief',
        thumb: '#Care',
        category: 'events',
    },
    {
        Id: '5',
        videoUrl: 'https://www.youtube.com/embed/3JZ_D3ELwOQ',
        title: 'Support Medical Relief',
        thumb: '#Care',
        category: 'events',
    },
    {
        Id: '6',
        videoUrl: 'https://www.youtube.com/embed/3JZ_D3ELwOQ',
        title: 'Support Medical Relief',
        thumb: '#Care',
        category: 'events',
    },
];

const VideoSection = (props) => {
    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // };

    const [activeFilter, setActiveFilter] = useState('all');
    const [activeIndex, setActiveIndex] = useState(null);

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    };

    const filteredDonation =
        activeFilter === 'all'
            ? Donation
            : Donation.filter((donation) => donation.category === activeFilter);

    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Videos'} pagesub={'Videos'} bg={Breadcumb} />

                <section className="about position-relative pt-120 pb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="sectionTitle text-center mb-70" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Moments of Change: Jainam Jivika Foundation&apos;s Journey
                                    </span>
                                    {/* <h2 className="sectionTitle__big"></h2> */}
                                    <p style={{fontSize:'24px'}}>Watch inspiring moments of our initiatives and see Jainam Jivika Foundation in action, bringing change to lives and communities.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="featureTab featureTab--style2">
                                    <ul className="unordered_list">
                                        <li
                                            className={activeFilter === 'all' ? 'active' : ''}
                                            onClick={() => handleFilterClick('all')}
                                        >
                                            All
                                        </li>
                                        <li
                                            className={activeFilter === 'education' ? 'active' : ''}
                                            onClick={() => handleFilterClick('education')}
                                        >
                                            Education
                                        </li>
                                        <li
                                            className={activeFilter === 'medical' ? 'active' : ''}
                                            onClick={() => handleFilterClick('medical')}
                                        >
                                            Medical
                                        </li>
                                        <li
                                            className={activeFilter === 'events' ? 'active' : ''}
                                            onClick={() => handleFilterClick('events')}
                                        >
                                            Events
                                        </li>
                                    </ul>
                                    <div className="filter_elements_wrapper row gx-3">
                                        {filteredDonation.map((donation, index) => (
                                            <div
                                                className="col-lg-4 col-sm-6 mb-35"
                                                style={{ height: '340px' }}
                                                key={index}
                                            >
                                                <div
                                                    className={`featureBlock ${activeIndex === index ? 'featureBlock--active' : ''
                                                        }`}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <div className="featureBlock__wrap">
                                                        <figure className="featureBlock__thumb">
                                                            <div className="featureBlock__thumb__link">
                                                                <iframe
                                                                    src={`${donation.videoUrl}?autoplay=${activeIndex === index ? 1 : 0
                                                                        }&mute=1&controls=0&modestbranding=1`}
                                                                    frameBorder="0"
                                                                    allow="autoplay"
                                                                    title={`Feature video for ${donation.title || 'this content'}`}
                                                                    className="featureBlock__video"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '250px',
                                                                    }}
                                                                ></iframe>
                                                            </div>
                                                        </figure>
                                                        <div className="featureBlock__content">
                                                            <h3 className="featureBlock__heading">
                                                                <Link
                                                                    to="#"
                                                                    onClick={(e) => e.preventDefault()}
                                                                    className="featureBlock__heading__link text-center"
                                                                >
                                                                    {donation.title}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default VideoSection;
