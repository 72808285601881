import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import CtaSection from '../../components/CtaSection/CtaSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import BreadcumbImg from '../../images/life-saver-army//breadcumb.jpg'
import AboutUs from '../../images/life-saver-army/about-us.png'
import Volunteer from '../../images/life-saver-army/volunteer.png'
import Donor from '../../images/life-saver-army/donor.png'
import Receiver from '../../images/life-saver-army/blood-receiver.png'
import Hospital from '../../images/life-saver-army/hospital.png'
import DonorBlack from '../../images/life-saver-army/donor-black.png'
import ReceiverBlack from '../../images/life-saver-army/donate.png'
import HospitalBlack from '../../images/life-saver-army/hospital-black.png'
import VolunteerBlack from '../../images/life-saver-army/volunteer-black.png'
import Influencer from '../../images/life-saver-army/influencer.png'
import HomeCtaHero from '../../images/life-saver-army/cta-img.jpg'

const Features2 = [
    {
        title: 'As a Donor',
        des: 'Sign up to donate blood and save lives in your local community.',
        icon: DonorBlack,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'As a Receiver',
        des: 'If you need blood, register with us and let us help you find a match quickly.',
        icon: ReceiverBlack,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'As a Hospital',
        des: 'Collaborate with us to ensure patients always have access to life-saving blood.',
        icon: HospitalBlack,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'As a Volunteer',
        des: 'Join our team to spread awareness organise donation events and support our platform.',
        icon: VolunteerBlack,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'As an Influencer',
        des: 'Inspire your followers to join Life Saver Army and encourage life-saving contributions.',
        icon: Influencer,
        width: '70%',
        duration: 1400,
    },
]

const LifeSeverArmy = (props) => {

   

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Life Saver Army'} pagesub={'Our Initiatives'} bg ={BreadcumbImg} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Life Saver Army" title="Saving Lives Together" para="Life Saver Army is one of our initiatives designed to connect blood and platelet donors with patients in urgent need. Whether you want to donate, receive blood or support through volunteering, Life Saver Army ensures life-saving help is always close at hand." button={false} duration={false} img={AboutUs} />
                </div>

                <div className='gray-bg' id='feature'>
                    <About4 subtitle="About the Initiative" title="What is Life Saver Army?" para="Life Saver Army is a platform that connects blood donors and receivers to make blood donation simple, fast and accessible. Our mission is to save lives by ensuring no patient struggles to find blood during emergencies." icon1={Hospital} icon2={Receiver} icon3={Donor} icon4={Volunteer} title3="Donors" des3="Register to donate blood and help someone in need." title2="Receivers" des2="Request blood or platelets and get connected with local donors quickly." title1="Hospitals" des1="Collaborate with us to streamline blood donation and improve patient care." title4="Volunteers" des4="Assist with events, raise awareness or help manage platform." title5="Influencers" des5="Use your voice to spread our mission and inspire others to donate and join." />
                </div>

                <ServiceSection subtitle="Your Trusted Partner in Life-Saving Moments" title="Why Choose Life Saver Army?" center={true} startIndex="17" endIndex="21" naviagtion={false} />

                <div className='gray-bg'>
                    <About3 bg="white" ctaSection={false} subtitle="Together for Life, One Drop at a Time" boxes={3} title="HOW CAN YOU HELP?" className='life-saver-army-div-responsive' Features2={Features2} ctabutton={true} button="KNOW MORE" />
                </div>


                <section className="about pt-40 pb-40 white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-30">
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Uniting Hearts to Save Lives
                                    </span>
                                    <h2 className="sectionTitle__big">Building a Community of Care</h2>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="aboutDetails text-center">
                                    <p className="aboutDetailsText mb-20">Life Saver Army is more than a platform; it’s a community where people come together to save lives. Whether you’re donating or receiving blood, your efforts create a ripple effect of kindness and hope.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <About3 /> */}

                {/* <div className='pt-120'>
                    <DonationListSection />
                </div> */}

                <div className='pt-40 gray-bg'>
                    <CtaSection subtitle="Why Wait?" title="Join Life Saver Army Today!" width={530} para="Be part of a caring community that saves lives every day. Whether you’re donating blood, supporting patients or volunteering your time, your contribution matters." button="Join Now and Save Lives!" ctaLink="https://lifesaverarmy.com/" img={HomeCtaHero} />
                </div>

                {/* <FunFactSection />


                <CtaSection />

                <Testimonial />
                
                <div className='pt-80'>
                    <TeamSection />
                </div> */}
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default LifeSeverArmy;
