import React from "react";
import { Link } from "react-router-dom";

const About2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="about">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-6 col-lg-6 mb-30">
            <div className="aboutContent aboutContent--style2 ps-3 ps-xl-0">
              <div className="sectionTitle mb-20">
                <span className="sectionTitle__small">
                  <i className="fa-solid fa-heart btn__icon"></i>
                  {props.subtitle}
                </span>
                <h2 className="sectionTitle__big">{props.title}</h2>
              </div>
              <p
                className="aboutContent__text"
                style={{ textAlign: "justify" }}
              >
                {props.para}
              </p>
              {/* <span className="aboutContent__quote">join our Action and everyone can help</span> */}
              {props.button && (
                <div className="aboutContent__buttons">
                  <Link
                    to="/donate"
                    onClick={ClickHandler}
                    className="btn btn--styleOne btn--secondary it-btn"
                  >
                    <span className="btn__text">donate now</span>
                    <i className="fa-solid fa-heart btn__icon"></i>
                    <span className="it-btn__inner">
                      <span className="it-btn__blobs">
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                      </span>
                    </span>
                    <svg
                      className="it-btn__animation"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                    >
                      <defs>
                        <filter>
                          <feGaussianBlur
                            in="SourceGraphic"
                            result="blur"
                            stdDeviation="10"
                          ></feGaussianBlur>
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                            result="goo"
                          ></feColorMatrix>
                          <feBlend
                            in2="goo"
                            in="SourceGraphic"
                            result="mix"
                          ></feBlend>
                        </filter>
                      </defs>
                    </svg>
                  </Link>
                  <Link
                    onClick={ClickHandler}
                    to="/volunteer-opportunities"
                    className="btn btn--styleOne btn--primary it-btn"
                  >
                    <span className="btn__text">became a volenteer</span>
                    <i className="fa-solid fa-heart btn__icon"></i>
                    <span className="it-btn__inner">
                      <span className="it-btn__blobs">
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                        <span className="it-btn__blob"></span>
                      </span>
                    </span>
                    <svg
                      className="it-btn__animation"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                    >
                      <defs>
                        <filter>
                          <feGaussianBlur
                            in="SourceGraphic"
                            result="blur"
                            stdDeviation="10"
                          ></feGaussianBlur>
                          <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                            result="goo"
                          ></feColorMatrix>
                          <feBlend
                            in2="goo"
                            in="SourceGraphic"
                            result="mix"
                          ></feBlend>
                        </filter>
                      </defs>
                    </svg>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 row justify-content-center">
            <div className="aboutThumb">
              {props.duration && (
                <div className="aboutThumb__text">
                  <span className="aboutThumb__text__title">..Since..</span>
                  <span className="aboutThumb__text__year">1998</span>
                </div>
              )}
              <figure className="aboutThumb__figure m-0">
                <img
                  src={props.img}
                  alt="Join the Movement"
                  // style={{ maxWidth: 482, maxHeight: 482, objectFit: "cover" }}
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
