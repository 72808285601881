import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import Breadcumb from '../../images/reform-army/breadcumb.jpg'
import AboutUs from '../../images/pledge-my-organ/plege-organ.jpg'
import Icon1 from '../../images/pledge-my-organ/organ-donation.png'
import Icon2 from '../../images/pledge-my-organ/honesty.png'
import Icon3 from '../../images/reform-army/mark-1.png'
import Icon4 from '../../images/reform-army/target-1.png'
import SaveLives from '../../images/pledge-my-organ/health.png'
import Hope from '../../images/pledge-my-organ/hope.png'
import Legacy from '../../images/pledge-my-organ/planning.png'
import MainCta from '../../images/pledge-my-organ/plege-organ-cta.jpg'


const Features2 = [
    {
        title: 'Save Lives',
        des: 'One donor can save up to eight lives, giving people a second chance at life.',
        icon: SaveLives,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Bring Hope',
        des: 'Your pledge can bring hope to patients and families waiting for a miracle.',
        icon: Hope,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Create a Legacy',
        des: 'By donating your organs, you leave behind a legacy of kindness and compassion.',
        icon: Legacy,
        width: '70%',
        duration: 1400,
    },
]

const PledgeMyOrgans = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Pledge My Organs'} pagesub={'Our Initiatives'} bg={Breadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Pledge My Organs" title="Commit to Life, Pledge Your Organs!" img={AboutUs} para={<> At Pledge My Organs, we are committed to raising awareness and encouraging individuals to pledge their organs. By making this simple yet impactful commitment, you can offer hope to those needing transplants, transforming lives forever! </>} />
                </div>

                <div className='gray-bg pb-40' id='feature'>
                    <About4 subtitle="About Our Initiative" title="The Ultimate Gift: A Pledge of Life" para="Pledge My Organs is dedicated to inspiring individuals to pledge their organs for donation. We focus on raising awareness and simplifying the pledging process, making it easy for anyone to commit to saving lives. By encouraging organ pledges, we aim to create a future where no one is left waiting for a life-saving transplant. Your pledge could be the difference that transforms someone’s life!" icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} button={false} title1="Our Mission" des1="Encouraging pledges for organ donation and raising awareness." title2="Power of a Pledge" des2="One pledge can bring hope." title3="Simplified Process" des3="We make pledging simple and accessible for everyone." title4="A Better Future" des4="Together, we aim to create a world where no one waits for the gift of life." />
                </div>

                {/* <div className='white-bg'>
                    <ServiceSection subtitle="Why Choose Reform Army?" title="A Platform Built for Change" startIndex="40" endIndex="45" naviagtion={true} />
                </div> */}

                <div className=''>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="About Organ Donation" title="The Impact of Your Organ Donation Pledge" Features2={Features2} ctabutton={false} className="reform-army-div-responsive" button="KNOW MORE" />
                </div>

                <div className="volunteer pt-40 pb-40 gray-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={MainCta} width={550} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Commit to Saving Lives
                                        </span>
                                        <h2 className="sectionTitle__big">Be the Reason Someone Gets a Second Chance at Life</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{ textAlign: 'justify' }}>
                                        Pledge My Organs encourages individuals to pledge their organs for donation, offering hope to those in need. We simplify the pledging process, raising awareness and ultimately saving lives.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <a onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" target='_blank' rel='noopener noreferrer' href="https://pledgemyorgan.mini91.com/">
                                            <span className="btn__text">Pledge Your Organs</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='pt-20'><strong>Work with Pledge My Organs and give families the opportunity to save multiple lives through a single act.</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default PledgeMyOrgans;
