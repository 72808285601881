import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import CtaSection from '../../components/CtaSection/CtaSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import icon1 from '../../images/icons/icon1.svg'
import icon2 from '../../images/icons/icon2.svg'
import icon3 from '../../images/icons/icon3.svg'
import Breadcumb from '../../images/sewak-army/breadcumb.jpg'
import AboutUs from '../../images/sewak-army/sevak-hero.jpg'
import Icon1 from '../../images/sewak-army/volunteer.png'
import Icon2 from '../../images/sewak-army/ngo.png'
import Icon3 from '../../images/sewak-army/organization.png'
import Icon4 from '../../images/sewak-army/school.png'
import BecomeVolunteer from '../../images/sewak-army/volunteer-black.png'
import Organization from '../../images/sewak-army/organization-black.png'
import School from '../../images/sewak-army/school-black.png'
import Influencer from '../../images/sewak-army/influencer.png'
import NGO from '../../images/sewak-army/ngo-black.png'
import HomeCtaHero from '../../images/home/home-cta-hero.jpg'
import HomeCta1 from '../../images/home/home-cta-1.jpg'
import HomeCta2 from '../../images/home/home-cta-2.jpg'
import HomeCta3 from '../../images/home/home-cta-3.jpg'
import HomeCta4 from '../../images/home/home-cta-4.jpg'
import HomeCta5 from '../../images/home/home-cta-5.jpg'




const sevakDetails = [
    {
        title: 'Volunteers',
        des: 'Discover projects that match your skills and interests.',
        icon: icon1,
        width: '100%',
        duration: 1000,
    },
    {
        title: 'NGOs',
        des: 'Connect with skilled volunteers to help you achieve your mission.',
        icon: icon2,
        width: '100%',
        duration: 1200,
    },
    {
        title: 'Organisations',
        des: 'Engage your teams in activities that leave a lasting social impact.',
        icon: icon3,
        width: '100%',
        duration: 1400,
    },
    {
        title: 'Schools & Colleges',
        des: 'Encourage students to participate in community-building initiatives.',
        icon: icon3,
        width: '100%',
        duration: 1400,
    },
    // {
    //     title: 'Influencers',
    //     des: 'Inspire your followers to register and support meaningful causes.',
    //     icon: icon3,
    //     width: '100%',
    //     duration: 1400,
    // },
]

const Features2 = [
    {
        title: 'Become a Volunteer',
        des: 'Register today on Sevak Army to find and actively participate in truly meaningful projects.',
        icon: BecomeVolunteer,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Get Your Organisation Involved',
        des: 'Inspire your team to take part in CSR initiatives that create a real impact in the society.',
        icon: Organization,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Engage Your School or College',
        des: 'Help students gain leadership experience while making a positive difference in the community.',
        icon: School,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Join as an Influencer',
        des: 'Promote volunteer opportunities and motivate your followers to support social causes.',
        icon: Influencer,
        width: '70%',
        duration: 1400,
    },
    {
        title: 'Support as an NGO',
        des: 'Find committed, reliable and highly skilled volunteers to help your organisation achieve its mission.',
        icon: NGO,
        width: '70%',
        duration: 1400,
    },
]

const SevakArmy = (props) => {


    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Sevak Army'} pagesub={'Our Initiatives'} bg={Breadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Sevak Army" title="Empower India Through Service and Volunteering" para="Sevak Army is a platform dedicated to connecting passionate individuals with trusted NGOs that provide meaningful opportunities to create lasting social change. Whether you are a volunteer, an organisation, a school or an NGO, Sevak Army provides a way for you to contribute your time, skills and resources to support communities across India." button={false} duration={false} img={AboutUs} />
                </div>

                <div className='gray-bg' id='feature'>
                    <About4 subtitle="About the Initiative" title="What is Sevak Army?" para="Sevak Army is a platform designed to bring volunteers and NGOs together for impactful social work. Whether you’re an individual eager to contribute or an organisation aiming to get involved in corporate social responsibility (CSR), Sevak Army makes volunteering easy, accessible and rewarding for everyone." sevakDetails={sevakDetails} icon1={Icon1} title1="Volunteers" des1="DISCOVER PROJECTS THAT MATCH YOUR SKILLS AND INTERESTS." icon2={Icon2} title2="NGOs" des2="CONNECT WITH SKILLED VOLUNTEERS TO HELP YOU ACHIEVE YOUR MISSION." icon3={Icon3} title3="Organisations" des3="ENGAGE YOUR TEAMS IN MEANINGFUL ACTIVITIES THAT LEAVE A LASTING SOCIAL IMPACT." icon4={Icon4} title4="Schools & Colleges" des4="ENCOURAGE STUDENTS TO PARTICIPATE IN COMMUNITY-BUILDING INITIATIVES." title5="Influencers" des5="Inspire your followers to register and support meaningful causes." />
                </div>

                <ServiceSection subtitle="Why Choose Sevak Army?" title="A Unique Platform to Contribute Meaningfully to Society" startIndex="12" endIndex="17" />

                {/* <JoinSection /> */}

                <div className='gray-bg'>
                    <About3 ctaSection={false} subtitle="Empower Change, One Step at a Time"  title="How Can You Contribute?" boxes={3} className="sevak-army-div-responsive" Features2={Features2} />
                </div>

                <div className='pt-40 '>
                    <CtaSection subtitle="Become a Sevak Today" width={530} title="Get Started with Sevak Army" para="Be part of something bigger by signing up as a volunteer organisation, NGO, school or influencer." cta="Use your time and skills to create meaningful social impact." img={HomeCtaHero} img1={HomeCta1} img2={HomeCta2} img3={HomeCta3} img4={HomeCta4} img5={HomeCta5} button="Start Your Journey" ctaLink="https://sevakarmy.mini91.com/" />
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default SevakArmy;
