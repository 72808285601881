import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import Breadcumb from '../../images/akshay/breadcumb.jpg'
import AboutUs from '../../images/akshay/akshay-netra-about.png'
import Icon1 from '../../images/akshay/donor-1.png'
import Icon2 from '../../images/akshay/receipient-2.png'
import Icon3 from '../../images/akshay/hospital-1.png'
import Icon4 from '../../images/akshay/volunteer-1.png'
import Pledge from '../../images/akshay/team.png'
import VolunteerUs from '../../images/akshay/handshake.png'
import Hospital from '../../images/akshay/hospital.png'
import AkshayImg from '../../images/akshay/main-cta.png'


const Features2 = [
    {
        title: 'Pledge Your Eyes',
        des: 'Register to donate your eyes and leave behind a legacy of hope.',
        icon: Pledge,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Volunteer with Us',
        des: 'Help spread awareness about eye donation through campaigns and outreach.',
        icon: VolunteerUs,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Support as a Hospital',
        des: 'Join hands with Akshay Netra to facilitate eye pledging and awareness to those in need.',
        icon: Hospital,
        width: '70%',
        duration: 1400,
    },
]

const AkshayNetra = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Akshay Netra'} pagesub={'Our Initiatives'} bg={Breadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Akshay Netra" img={AboutUs} title="Restoring Vision, Spreading Hope" para={<>Akshay Netra is a platform dedicated to transforming lives through eye donation and awareness. By connecting donors, recipients and hospitals, we work to restore vision and bring independence to those affected by preventable blindness. Whether you’re an individual pledging your eyes, a hospital seeking to collaborate or a volunteer eager to make a difference, Akshay Netra provides meaningful ways to contribute to this life-changing cause.</>} />
                </div>

                <div className='gray-bg pb-40' id='feature'>
                    <About4 subtitle="About The Initiative" title="What is Akshay Netra?" icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} para="Akshay Netra is committed to eliminating preventable blindness by encouraging eye pledges and making the process seamless, accessible and impactful. We collaborate with leading eye banks and hospitals to raise awareness and ensure more people pledge their eyes and help out for a meaningful cause." button={false} title1="Donors" des1="Support eye donation awareness through financial or in-kind contributions." title2="Organisations" des2="Inspire your team with awareness programs and pledge activities, fostering lasting commitment." title3="Hospitals" des3="Collaborate with us to match donors with recipients efficiently." title4="Volunteers" des4="Raise awareness and inspire more people to pledge their eyes." />
                </div>

                <div className='white-bg'>
                    <ServiceSection subtitle="Why Choose Akshay Netra?" title="Beyond Vision: A Promise of Light" center={true} startIndex="32" endIndex="36" naviagtion={true} />
                </div>

                <div className='gray-bg'>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="Join Us in Bringing the Gift of Sight" title="How Can You Contribute?" className="akshay-div-responsive" Features2={Features2} ctabutton={false} button="KNOW MORE" />
                </div>

                <div className="volunteer pt-40 pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={AkshayImg} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Get Started with Akshay Netra
                                        </span>
                                        <h2 className="sectionTitle__big">Pledge Your Eyes, Today!</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{textAlign:'justify', fontSize:'21px'}}>
                                        Take the first step toward making a difference in someone’s life. Join us to restore vision and spread hope across communities.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <a onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" target='_blank' rel='noopener noreferrer' href="https://akshaynetra.mini91.com/">
                                            <span className="btn__text">Start Your Journey with Akshay Netra</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='pt-20'><strong>Let’s create a world where everyone can see the future!</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default AkshayNetra;
