import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import About3 from '../../components/about3/about3';
import About4 from '../../components/about4/about4';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import JainsBreadcumb from '../../images/jains/breadcumb.png'
import CtaMain from '../../images/jains/cta-main.png'
import AboutUs from '../../images/jains/about-us.png'
import Icon1 from '../../images/jains/event-1.png'
import Icon2 from '../../images/jains/resources-1.png'
import Icon3 from '../../images/jains/youth-1.png'
import Icon4 from '../../images/jains/community-1.png'
import Events from '../../images/jains/conference.png'
import VolunteerWithUs from '../../images/jains/team.png'
import Resources from '../../images/jains/resources.png'


const Features2 = [
    {
        title: 'Attend Events',
        des: 'Join workshops, philosophy sessions and interactive activities to explore Jainism in depth.',
        icon: Events,
        width: '80%',
        duration: 1000,
    },
    {
        title: 'Volunteer with Us',
        des: 'Help organise events, create meaningful content or support initiatives that promote Jain values.',
        icon: VolunteerWithUs,
        width: '94%',
        duration: 1200,
    },
    {
        title: 'Access Resources',
        des: 'Explore our curated library of books, videos and tools designed to make Jain teachings accessible and enriching.',
        icon: Resources,
        width: '70%',
        duration: 1400,
    },
]

const YoungJains = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Young Jains'} pagesub={'Our Initiatives'} bg={JainsBreadcumb} />

                <div className='pt-40 pb-40'>
                    <About2 subtitle="Young Jains" img={AboutUs} title="Preserving Heritage, Inspiring Generations" para={<> Young Jains, an initiative by Jainam Jivika Foundation, empowers the younger generation to embrace Jain values while staying connected to modern life. Through books, videos, workshops and networking opportunities, we connect tradition and the contemporary world, fostering a strong community rooted in Jain principles.</>} />
                </div>

                <div className='gray-bg' id='feature'>
                    <About4 subtitle="About the Initiative" title="What is Young Jains?" para="At Young Jains, we keep Jain values alive by making them easy to understand and apply in daily life. We teach essential principles like kindness, mindfulness and nonviolence through fun workshops and activities. Our goal is to help young people stay connected to their roots while living meaningful and ethical lives." button={false} title1="Workshops and Events" des1="Interactive sessions to deepen understanding and connection with Jain values." icon1={Icon1} icon2={Icon2} icon3={Icon3} icon4={Icon4} title2="Educational Resources " des2="Books, videos and comprehensive guides that bring Jain teachings to life." title3="Youth Engagement" des3="Activities and mentorship programmes to inspire compassion and mindfulness." title4="Community Building" des4="Creating spaces for young individuals to connect, share and grow with Jain values." />
                </div>

                <div className='white-bg'>
                    <ServiceSection subtitle="Why Choose Young Jains?" center={true} title="Bringing Jain Wisdom to Modern Life" startIndex="36" endIndex="40" naviagtion={true} />
                </div>

                <div className='gray-bg'>
                    <About3 bg="gray-bg" ctaSection={false} subtitle="Be a Part of Preserving Jain Heritage" title="How Can You Get Involved?" className='young-jains-div-responsive' Features2={Features2} ctabutton={false} button="KNOW MORE" />
                </div>

                <div className="volunteer pt-40 pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={CtaMain} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Get Started with Us
                                        </span>
                                        <h2 className="sectionTitle__big">Step Up with Young Jains!</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{ textAlign: 'justify' }}>
                                        Be a part of a growing community that celebrates and preserves the rich legacy of Jainism. Together, let’s inspire compassion, mindfulness and meaningful connections for a better tomorrow.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <a onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" target='_blank' rel='noopener noreferrer' href="https://youngjains.com/">
                                            <span className="btn__text">Explore More</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <p className='pt-20'><strong>Discover a purposeful life – where tradition and modernity come together.</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default YoungJains;
