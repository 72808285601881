import React from 'react';

const CtaSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className="volunteer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-20">
                        <div className="volunteerUser">
                            <div className="volunteerUser__box">
                                <div className="volunteerUser__thumb">
                                    <img src={props.img} width={props.width ? props.width : ''} alt="Join Us and Make a Difference" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-30">
                        <div className="aboutContent aboutContent--style3">
                            <div className="sectionTitle mb-20">
                                <span className="sectionTitle__small align-items-start">
                                    <i className="fa-solid fa-heart btn__icon mt-1"></i>
                                   {props.subtitle}
                                </span>
                                <h2 className="sectionTitle__big">{props.title}</h2>
                            </div>
                            {/* <span className="aboutContent__quote text-uppercase">More than 200+ People alreyday Join with us</span> */}
                            <p className="aboutContent__text" style={{fontSize:'21px', textAlign:'justify'}}>
                               {props.para}
                            </p>
                            {props.cta && <h6 className='pb-4' style={{textAlign:'justify'}}>{props.cta}</h6>}
                            <div className="aboutContent__buttons">
                                <a onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" target='_blank' rel='noopener noreferrer' href={props.ctaLink ? props.ctaLink : "/contact-us"}>
                                    <span className="btn__text">{props.button}</span>
                                    <i className="fa-solid fa-heart btn__icon"></i>
                                    <span className="it-btn__inner">
                                        <span className="it-btn__blobs">
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                        </span>
                                    </span>
                                    <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter>
                                                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                </feGaussianBlur>
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                </feColorMatrix>
                                                <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CtaSection;