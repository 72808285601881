import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import JjfLogo from "../../images/jjf-india-new-logo.png";
import MobileMenu from "../MobileMenu/MobileMenu";

import { Helmet } from "react-helmet";
const Header = (props) => {
  const [mobailActive, setMobailState] = useState(false);
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("Home");


  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setPageTitle("Home");
        break;
      case "/who-we-are":
        setPageTitle("Who We Are");
        break;
      case "/our-journey":
        setPageTitle("Our Journey");
        break;
      case "/leadership-team":
        setPageTitle("Leadership Team");
        break;
      case "/sevak-army":
        setPageTitle("Sevak Army");
        break;
      case "/life-saver-army":
        setPageTitle("Life Saver Army");
        break;
      case "/my-food-checker":
        setPageTitle("My Food Checker");
        break;
      case "/mind-unload":
        setPageTitle("Mind Unload");
        break;
      case "/akshay-netra":
        setPageTitle("Akshay Netra");
        break;
      case "/young-jains":
        setPageTitle("Young Jains");
        break;
      case "/reform-army":
        setPageTitle("Reform Army");
        break;
      case "/pledge-my-organs":
        setPageTitle("Pledge My Organs");
        break;
      case "/success-stories":
        setPageTitle("Success Stories");
        break;
      case "/contact-us":
        setPageTitle("Contact Us");
        break;
      case "/tree-plantation":
        setPageTitle("Tree Plantation");
        break;
      case "/blood-donation":
        setPageTitle("Blood Donation");
        break;
      case "/orphanage-visit":
        setPageTitle("Orphanage Visit");
        break;
      case "/handicap-center-visit":
        setPageTitle("Handicap Center Visit");
        break;
      case "/sweator-donation":
        setPageTitle("Sweator Donation");
        break;
      case "/school-activity":
        setPageTitle("School Activity");
        break;
      case "/flood-relief-material":
        setPageTitle("Flood Relief Material");
        break;
      default:
        setPageTitle("");
    }
  }, [location]);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };


  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);


    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <div style={{overflowX:'hidden'}}>
      <header
        className={`header header--styleOne sticky-on  ${isSticky ? "sticky" : ""
          }  ${props.hclass}`}
      >
        <Helmet>
          <title>JJFIndia | {pageTitle}</title>
        </Helmet>

        <div id="sticky-placeholder"></div>
        <div className="container container--custom">
          <div className="row">
            <div className="col-12">
              <div className="header__wrapper">
                <div className="header__logo">
                  <Link
                    onClick={ClickHandler}
                    to="/"
                    className="header__logo__link"
                  >
                    <img
                      src={JjfLogo}
                      width={260}
                      alt="Join the Movement"
                      className="header__logo__image"
                    />
                  </Link>
                </div>

                <div className="header__menu">
                  <nav className="mainMenu">
                    <ul>
                      <li className="dropdown">
                        <Link onClick={ClickHandler} to="/">
                          Home
                        </Link>
                      </li>
                      <li className="dropdown">
                        <Link to="#" onClick={(e) => e.preventDefault()} style={{ whiteSpace: 'nowrap' }}>
                          About Us
                        </Link>
                        <ul className="dropdown_menu dropdown_menu-2">
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/who-we-are" style={{ whiteSpace: 'nowrap' }}>
                              Who We Are?
                            </Link>
                          </li>
                          <li className="dropdown_item-2">
                            <Link onClick={ClickHandler} to="/our-journey" style={{ whiteSpace: 'nowrap' }}>
                              Our Journey
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/leadership-team" style={{ whiteSpace: 'nowrap' }}>
                              Leadership Team{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="#" onClick={(e) => e.preventDefault()} style={{ whiteSpace: 'nowrap' }}>
                          Our Initiatives
                        </Link>
                        <ul className="dropdown_menu dropdown_menu-2">
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/sevak-army" style={{ whiteSpace: 'nowrap' }}>
                              SevakArmy.com
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/life-saver-army" style={{ whiteSpace: 'nowrap' }}>
                              {" "}
                              LifeSaverArmy.com{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/my-food-checker" style={{ whiteSpace: 'nowrap' }}>
                              {" "}
                              MyFoodChecker.com{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/mind-unload" style={{ whiteSpace: 'nowrap' }}>
                              {" "}
                              MindUnload.com{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/akshay-netra" style={{ whiteSpace: 'nowrap' }}>
                              {" "}
                              AkshayNetra.com{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/young-jains" style={{ whiteSpace: 'nowrap' }}>
                              {" "}
                              YoungJains.com
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/reform-army" style={{ whiteSpace: 'nowrap' }}>
                              ReformArmy.com{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/pledge-my-organs" style={{ whiteSpace: 'nowrap' }}>
                            PledgeMyOrgans.com{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="#" onClick={(e) => e.preventDefault()} style={{ whiteSpace: 'nowrap' }}>
                          Impact & Stories
                        </Link>
                        <ul className="dropdown_menu dropdown_menu-2">
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/success-stories" style={{ whiteSpace: 'nowrap' }}>
                              Success Stories
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/testimonials" style={{ whiteSpace: 'nowrap' }}>
                              Testimonials{" "}
                            </Link>
                          </li>
                          <li className="dropdown_item-1">
                            <Link onClick={ClickHandler} to="/achievements" style={{ whiteSpace: 'nowrap' }}>
                              Achievements{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <Link to="#" onClick={(e) => e.preventDefault()}>
                          Gallery
                        </Link>
                        <ul className="dropdown_menu dropdown_menu-2">
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/tree-plantation" style={{ whiteSpace: 'nowrap' }}>
                              Tree Plantation
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/blood-donation" style={{ whiteSpace: 'nowrap' }}>
                              Blood Donation
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/orphanage-visit" style={{ whiteSpace: 'nowrap' }}>
                              Orphanage Visit
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link
                              onClick={ClickHandler}
                              to="/handicap-center-visit"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Handicap Center Visit
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/sweator-donation" style={{ whiteSpace: 'nowrap' }}>
                              Sweater Donation
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link onClick={ClickHandler} to="/school-activity" style={{ whiteSpace: 'nowrap' }}>
                              School Activity
                            </Link>
                          </li>
                          <li className="dropdown_item-3">
                            <Link
                              onClick={ClickHandler}
                              to="/flood-relief-material"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Flood Relief Material
                            </Link>
                          </li>

                        </ul>
                      </li>


                      <li className="dropdown">
                        <Link to="#" onClick={(e) => e.preventDefault()} style={{ whiteSpace: 'nowrap' }}>
                          Join Us
                        </Link>
                        <ul className="dropdown_menu dropdown_menu-2">
                          <li className="dropdown_item-1">
                            <Link
                              onClick={ClickHandler}
                              to="/volunteer-opportunities"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Volunteer Opportunities
                            </Link>
                          </li>
                          <li className="dropdown_item-2">
                            <Link onClick={ClickHandler} to="/donate" style={{ whiteSpace: 'nowrap' }}>
                              Donate{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        <Link onClick={ClickHandler} to="/contact-us" style={{ whiteSpace: 'nowrap' }}>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="header__right">
                 
                  <div className="header__button" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <a
                      target='_blank' rel="noopener noreferrer" href='/pdfs/jainam-jivika-foundation-profile.pdf'
                      className="btn btn--styleOne btn--primary it-btn"
                    >
                      <span
                        className="btn__text"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Profile
                      </span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </a>
                    <Link
                      to="/donate"
                      onClick={ClickHandler}
                      className="btn btn--styleOne btn--secondary it-btn"
                    >
                      <span
                        className="btn__text"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        donate now
                      </span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="header header--mobile cc-header-menu mean-container position-relative"
        id="meanmenu"
      >
        <div className="mean-bar headerBurgerMenu">
          <Link onClick={ClickHandler} to="/">
            <img
              className="mean-bar__logo"
              alt="Techkit"
              src={JjfLogo}
              width={200}
            />
          </Link>
          <div className="header__right header-right-responsive">

            <div className="header__button" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <a
                target='_blank' rel="noopener noreferrer" href='/pdfs/jainam-jivika-foundation-profile.pdf'
                className="btn btn--styleOne btn--primary it-btn"
              >
                <span
                  className="btn__text"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Profile
                </span>
                <i className="fa-solid fa-heart btn__icon"></i>
                <span className="it-btn__inner">
                  <span className="it-btn__blobs">
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                  </span>
                </span>
                <svg
                  className="it-btn__animation"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                >
                  <defs>
                    <filter>
                      <feGaussianBlur
                        in="SourceGraphic"
                        result="blur"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                        result="goo"
                      ></feColorMatrix>
                      <feBlend
                        in2="goo"
                        in="SourceGraphic"
                        result="mix"
                      ></feBlend>
                    </filter>
                  </defs>
                </svg>
              </a>
              <Link
                to="/donate"
                onClick={ClickHandler}
                className="btn btn--styleOne btn--secondary it-btn"
              >
                <span
                  className="btn__text"
                  style={{ whiteSpace: "nowrap" }}
                >
                  donate now
                </span>
                <i className="fa-solid fa-heart btn__icon"></i>
                <span className="it-btn__inner">
                  <span className="it-btn__blobs">
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                    <span className="it-btn__blob"></span>
                  </span>
                </span>
                <svg
                  className="it-btn__animation"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                >
                  <defs>
                    <filter>
                      <feGaussianBlur
                        in="SourceGraphic"
                        result="blur"
                        stdDeviation="10"
                      ></feGaussianBlur>
                      <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                        result="goo"
                      ></feColorMatrix>
                      <feBlend
                        in2="goo"
                        in="SourceGraphic"
                        result="mix"
                      ></feBlend>
                    </filter>
                  </defs>
                </svg>
              </Link>
            </div>
            <div className="header__actions">
              <ul>
                <li>
                  <button
                    className={`headerBurgerMenu__button sidebarBtn ${mobailActive ? "opened" : ""
                      }`}
                    aria-label="Main Menu"
                    onClick={() => setMobailState(!mobailActive)}
                  >
                    <svg width="50" height="50" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`cc cc--slideNav ${mobailActive ? "show" : ""}`}>
        <div className="cc__logo mb-40">
          <Link onClick={ClickHandler} to="/">
            <img className="mean-bar__logo" alt="Techkit" src={JjfLogo} />
          </Link>
        </div>

        <div className="offscreen-navigation mb-20">
          <nav className="menu-main-primary-container">
            <MobileMenu />
          </nav>
        </div>
        {/* <div className="itSocial itSocial--sidebar mb-40">
                    <ul>
                        <li>
                            <Link onClick={ClickHandler} className="facebook" to="/" rel="nofollow">
                                <i className="fab fa-facebook-f"></i>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} className="twitter" to="/" rel="nofollow">
                                <i className="fab fa-twitter"></i>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} className="instagram" to="/" rel="nofollow">
                                <i className="fab fa-instagram"></i>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} className="linkedin" to="/" rel="nofollow">
                                <i className="fab fa-linkedin-in"></i>
                            </Link>
                        </li>
                    </ul>
                </div> */}
        <div className="cc__button button-display">
          <div className="header__button" style={{ display: 'flex', flexDirection: 'column', rowGap: '18px' }}>
            <a
              className="btn btn--styleOne btn--primary it-btn"
              style={{ justifyContent: 'center' }}
              target='_blank' rel="noopener noreferrer" href='/pdfs/jainam-jivika-foundation-profile.pdf'
            >
              <span className="btn__text">Profile</span>
              <i className="fa-solid fa-heart btn__icon"></i>
              <span className="it-btn__inner">
                <span className="it-btn__blobs">
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                </span>
              </span>
              <svg
                className="it-btn__animation"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
              >
                <defs>
                  <filter id="goo">
                    <feGaussianBlur
                      in="SourceGraphic"
                      result="blur"
                      stdDeviation="10"
                    ></feGaussianBlur>
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                      result="goo"
                    ></feColorMatrix>
                    <feBlend
                      in2="goo"
                      in="SourceGraphic"
                      result="mix"
                    ></feBlend>
                  </filter>
                </defs>
              </svg>
            </a>
            <Link
              onClick={ClickHandler}
              className="btn btn--styleOne btn--secondary it-btn"
              style={{ justifyContent: 'center' }}
              to="/contact-us"
            >
              <span className="btn__text">Donate Now</span>
              <i className="fa-solid fa-heart btn__icon"></i>
              <span className="it-btn__inner">
                <span className="it-btn__blobs">
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                  <span className="it-btn__blob"></span>
                </span>
              </span>
              <svg
                className="it-btn__animation"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
              >
                <defs>
                  <filter id="goo">
                    <feGaussianBlur
                      in="SourceGraphic"
                      result="blur"
                      stdDeviation="10"
                    ></feGaussianBlur>
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                      result="goo"
                    ></feColorMatrix>
                    <feBlend
                      in2="goo"
                      in="SourceGraphic"
                      result="mix"
                    ></feBlend>
                  </filter>
                </defs>
              </svg>
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps)(Header);
